<template>
    <div
        class="refund-request-radio ot-radio"
        :disabled="disabled"
        :class="{ 'ot-highlight': selected, disabled }"
    >
        <!--suppress HtmlFormInputWithoutLabel -->
        <input
            :id="`refund-request-radio_${guid}`"
            type="radio"
            :checked="selected"
            :disabled="disabled"
            @click="onInput($event)"
        >
        <label
            class="ot-radio-label refund-request-radio__label"
            :for="`refund-request-radio_${guid}`"
        >
            <slot />
        </label>
    </div>
</template>

<script setup lang="ts">
interface Props {
    guid: string;
    selected: boolean;
    disabled: boolean;
}

type Emits = {
    (e: 'input', value: boolean): void;
};

withDefaults(defineProps<Props>(), {
    selected: false,
    disabled: false,
});

const emit = defineEmits<Emits>();

const onInput = (event: Event): void => {
    // TODO: https://app.clickup.com/t/86c2qmj0w
    if (event.target && event.target instanceof HTMLInputElement) {
        const { target } = event;
        emit('input', target.checked);
    }
};
</script>

<style lang="scss" scoped>
.refund-request-radio {
    box-sizing: border-box;

    border: 1.5px solid var(--ot-shop-color-box-outline-dropdown);
    box-shadow: 0 2px 4px -2px var(--ot-shop-color-box-dropdown);
    border-radius: 0.375rem;

    &:not(:last-child) {
        margin: 0.5rem 0;
    }

    &.ot-radio::v-deep > input[type='radio'] + label.ot-radio-label {
        padding: 1rem 1.25rem 1rem 3.25rem;
        text-align: initial;

        &::before,
        &::after {
            top: 50%;
            left: 1.25rem;
            margin-top: -0.625rem;
        }
    }

    &.ot-highlight.disabled {
        box-shadow: 0 0 0 2px var(--ot-input-color-disabled-invert),
            0 0 0 4px var(--ot-color-core-brand-faded) !important;
    }
}
</style>
