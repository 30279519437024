import Vue from 'vue';
import VueRouter, { type RouteConfig } from 'vue-router';

import ErrorView from '../Error.vue';
import OrderView from './Order.vue';
import OrderStatusView from './views/OrderStatus.vue';
import OrderPosView from './views/OrderPos.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/error',
        name: 'error',
        component: ErrorView,
    },
    {
        path: '/',
        component: OrderView,
        children: [
            {
                path: '',
                redirect: { name: 'error' },
            },
            {
                path: '/status/:order_id/:status?',
                redirect: { name: 'order-status' },
            },
            {
                path: '/download/:order_id/:status?',
                redirect: { name: 'order-status' },
            },
            {
                path: '/pos/:order_id/:status?',
                component: OrderPosView,
                name: 'order-pos',
            },
            {
                path: '/:order_id/:status?',
                component: OrderStatusView,
                name: 'order-status',
            },
        ],
    },
];

const router = new VueRouter({
    mode: 'history',
    routes,
});

export default router;
