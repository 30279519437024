<template>
    <div
        v-if="
            showDownloadButton ||
                isAppicEnabled ||
                isPartyPayEnabled ||
                isMaveEnabled ||
                isTicketKeeperEnabled ||
                isAppleWalletEnabled ||
                isCloseEnabled
        "
        class="order-ticket-actions"
        data-testid="order-ticket-actions"
    >
        <AppleWallet
            v-if="isAppleWalletEnabled && ticket"
            :ticket="ticket"
        />
        <a
            v-if="isAppicEnabled"
            :href="appicUrl"
            class="order-ticket-actions__btn"
            data-testid="order-ticket-actions-appic"
            target="_blank"
        >
            <button class="ot-button is-fullwidth appic">
                <i18n path="order.components.order_ticket_actions.open_in">
                    <template #logo>
                        <img
                            src="../../../../assets/images/brands/appic.svg"
                            alt="Appic"
                        >
                    </template>
                </i18n>
            </button>
        </a>
        <a
            v-if="isCloseEnabled"
            :href="closeUrl"
            class="order-ticket-actions__btn"
            data-testid="order-ticket-actions-close"
            target="_blank"
        >
            <button class="ot-button is-fullwidth close">
                <i18n path="order.components.order_ticket_actions.open_in">
                    <template #logo>
                        <img
                            :src="closeImageSrc"
                            :alt="closeImageAlt"
                        >
                    </template>
                </i18n>
            </button>
        </a>
        <a
            v-if="isMaveEnabled"
            :href="maveUrl"
            class="order-ticket-actions__btn"
            data-testid="order-ticket-actions-mave"
            target="_blank"
        >
            <button class="ot-button is-fullwidth mave">
                <i18n path="order.components.order_ticket_actions.open_in">
                    <template #logo>
                        <img
                            src="../../../../assets/images/brands/mave.svg"
                            alt="Mave"
                            class="mave-logo"
                        >
                        &nbsp;&nbsp;Mave
                    </template>
                </i18n>
            </button>
        </a>
        <a
            v-if="isPartyPayEnabled"
            :href="partyPayUrl"
            class="order-ticket-actions__btn"
            data-testid="order-ticket-actions-party-pay"
            target="_blank"
        >
            <button class="ot-button is-fullwidth party-pay">
                <i18n path="order.components.order_ticket_actions.open_in">
                    <template #logo>
                        <img
                            src="../../../../assets/images/brands/party-pay.svg"
                            alt="Party Pay"
                        >
                    </template>
                </i18n>
            </button>
        </a>
        <a
            v-if="isTicketKeeperEnabled"
            :href="ticketKeeperUrl"
            class="order-ticket-actions__btn"
            data-testid="order-ticket-actions-ticket-keeper"
            target="_blank"
        >
            <button class="ot-button is-fullwidth ticket-keeper">
                <i18n path="order.components.order_ticket_actions.open_in">
                    <template #logo>
                        <img
                            src="../../../../assets/images/brands/ticket-keeper.svg"
                            alt="Ticket Keeper"
                        >
                    </template>
                </i18n>
            </button>
        </a>
        <a
            v-if="showDownloadButton && downloadLink"
            target="_blank"
            class="order-ticket-actions__btn"
            data-testid="order-ticket-actions-download"
            :href="downloadLink"
        >
            <button class="ot-button is-fullwidth">
                <i class="oti oti-download ot-button-icon--left" />
                {{ $t('order.components.order_ticket_actions.download') }}
            </button>
        </a>
    </div>
</template>

<script setup lang="ts">
import urljoin from 'url-join';
import type { IOrderTicket, OrderClient } from '@openticket/lib-order';
import { computed } from 'vue';
import type { Whitelabel } from '@openticket/lib-whitelabels';
import type { CustomShopSettingsClient } from '@openticket/lib-custom-shop-settings';
import CloseLogo from '../../../../assets/images/brands/close.svg';
import PulseLogo from '../../../../assets/images/brands/pulse.svg';
import { OrderLinkReplacer } from '../../../../utils/linkreplacer';
import AppleWallet from './actions/AppleWallet.vue';
import { injectOrFail } from '../../../../services/util/injectOrFail';

interface Props {
    showDownloadButton: boolean;
    downloadLink?: string | null;
    ticket?: IOrderTicket;
}

const props = withDefaults(defineProps<Props>(), {
    downloadLink: undefined,
    ticket: undefined,
});

const order = injectOrFail<OrderClient>('order');
const settings = injectOrFail<CustomShopSettingsClient>('settings');
const whitelabel = injectOrFail<Whitelabel>('whitelabel');

const linkReplacer = computed<OrderLinkReplacer>(() => new OrderLinkReplacer(order, props.ticket));

const isAppicEnabled = computed<boolean>(() => (
    !!settings
        && !!settings.static.order.enableAppic
        && !!whitelabel.order.appic_url
        && order.data.status === 'paid'
));

const isCloseEnabled = computed<boolean>(() => (
    !!settings
        && !!settings.static.order.components.eventCard.closeUrl
        && order.data.status === 'paid'
));

const isPartyPayEnabled = computed<boolean>(() => (
    !!settings
        && !!settings.static.order.enablePartyPay
        && !!whitelabel.order.party_pay_url
        && order.data.status === 'paid'
));

const isMaveEnabled = computed<boolean>(() => (
    !!settings
        && !!settings.static.order.enableMave
        && !!whitelabel.order.mave_url
        && order.data.status === 'paid'
));

const isTicketKeeperEnabled = computed<boolean>(() => (
    !!settings
        && !!settings.static.order.enableTicketKeeper
        && !!whitelabel.order.ticket_keeper_url
        && order.data.status === 'paid'
));

const isAppleWalletEnabled = computed<boolean>(() => {
    if (
        whitelabel.order.wallet_url
        && settings
        && settings.static.order.enablePassbook
        && order.data.status === 'paid'
        && !whitelabel.order.disable_passbook
    ) {
        return window.IsAppleDevice || false;
    }

    return false;
});

const appicUrl = computed<string>(() => {
    if (!whitelabel.order.appic_url) {
        return '';
    }

    return urljoin(
        whitelabel.order.appic_url,
        `?order_uuid=${order.data.guid}`,
    );
});

const closeUrl = computed<string>(() => {
    if (
        !settings
        || !settings.static.order.components.eventCard.closeUrl
    ) {
        return '';
    }

    return settings.static.order.components.eventCard.closeUrl;
});

const closeImageSrc = computed<string>(() => {
    if (
        !settings
        || !settings.static.order.components.eventCard.closeUrl
        || !settings.static.order.components.eventCard.closeUrl.startsWith('https://get-pul.se/')
    ) {
        return CloseLogo;
    }

    return PulseLogo;
});

const closeImageAlt = computed<string>(() => {
    if (
        !settings
        || !settings.static.order.components.eventCard.closeUrl
        || !settings.static.order.components.eventCard.closeUrl.startsWith('https://get-pul.se/')
    ) {
        return 'Close';
    }

    return 'Pulse';
});

const partyPayUrl = computed<string>(() => {
    if (!whitelabel.order.party_pay_url) {
        return '';
    }

    let party_pay_url = whitelabel.order.party_pay_url.replace(
        /{{\s*order_id\s*}}/,
        order.data.guid,
    );

    if (props.ticket) {
        party_pay_url = urljoin(
            party_pay_url,
            `?event_id=${props.ticket.ticket.event_id}`,
            `?ticket_id=${props.ticket.ticket.guid}`,
            `?order_ticket_id=${props.ticket.guid}`,
        );
    }

    return party_pay_url;
});

const maveUrl = computed<string>(() => {
    if (!whitelabel.order.mave_url) {
        return '';
    }

    return whitelabel.order.mave_url.replace(linkReplacer.value, '$1');
});

const ticketKeeperUrl = computed<string>(() => {
    if (!whitelabel.order.ticket_keeper_url) {
        return '';
    }

    return whitelabel.order.ticket_keeper_url.replace(linkReplacer.value, '$1');
});
</script>

<style lang="scss" scoped>
.order-ticket-actions {
    &:not(:last-child) {
        border-bottom: 2px solid rgba(0, 0, 0, 0.03);
        margin-bottom: var(--ot-spacing-default);
    }

    &__btn {
        margin-bottom: var(--ot-spacing-xs);
        display: flex;

        & span {
            display: flex;
            align-items: center;
        }
        &:last-child {
            margin-bottom: 0;
        }
        & .appic {
            background-color: var(--ot-color-accent-pink-dark);
            & span img {
                height: 20px;
            }
        }
        & .close {
            background-color: var(--ot-shop-color-black);
            & span img {
                // Close wallet app logo is bigger in height because the "l" extends outside the normal flow of the logo
                // That is why it appears to be uncentered, by slightly adjusting the bottom spacing it appears centered now
                margin-bottom: 6px;
            }
        }
        & .party-pay {
            background: hsl(341, 66%, 49%);
            & span img {
                // Partypay wallet app logo is bigger in height because the "y" & "p" extends outside the normal flow of the logo
                // That is why it appears to be uncentered, by slightly adjusting the bottom spacing it appears centered now
                margin-top: 3px;
            }
        }
        & .mave {
            background: hsl(255, 61%, 56%);
            & span img {
                margin: 0 0 0 var(--ot-spacing-xs);
                height: 2.25rem;
            }
        }
        & .ticket-keeper {
            background: hsl(240, 100%, 72%);
            & span img {
                margin: 0 0 0 var(--ot-spacing-xs);
                height: 1.75rem;
            }
        }
        & img {
            max-height: 100%;
            margin-left: var(--ot-spacing-xs);
        }
    }
}
</style>
