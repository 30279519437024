<!-- eslint-disable vue/multi-word-component-names -->
<!-- TODO: Make this a multi-word-component -->
<template>
    <div
        v-if="baseInitialized"
        class="error-view"
    >
        <BrandedHeader />

        <div class="ot-card error-view__card">
            <div class="error-view__card__icon">
                <div class="oti oti-alert is-large" />
            </div>

            <h2 class="error-view__card__title">
                {{ $t(shownTitle) }}
            </h2>

            <p class="error-view__card__description">
                {{ $t(shownDescription) }}
            </p>

            <p class="error-view__card__session">
                Session ID: {{ $log.session }}
            </p>

            <a
                v-if="redirectUrl"
                :href="redirectUrl"
            >
                <button
                    class="
                        ot-button
                        is-fullwidth is-large
                        error-view__card__button
                    "
                >
                    {{ $t('shop.components.shop_error.retry_button') }}
                    <i class="ot-button-icon--right oti oti-arrow-right" />
                </button>
            </a>
        </div>

        <BrandedFooter hide-cookie-link />
    </div>
</template>

<script setup lang="ts">
import type { Log } from '@openticket/lib-log';
import { computed, inject, type Ref } from 'vue';
import type { VueLocalization } from '@openticket/vue-localization';
import { useRouter, useRoute } from 'vue-router/composables';
import { injectOrFail } from '../services/util/injectOrFail';

interface Props {
    title?: string;
    description?: string;
}

const props = defineProps<Props>();

const baseInitialized = inject<Ref<boolean>>('baseInitialized');
const localization = injectOrFail<VueLocalization>('localization');
const log = injectOrFail<Log>('log');

const route = useRoute();
const router = useRouter();

const { sessionId } = route.query;

if (!sessionId) {
    void router.replace({
        path: route.path,
        query: {
            ...route.query,
            sessionId: log.session,
        },
    });
}

const shownTitle = computed<string>(() => {
    if (route.query.title) {
        const translated = $t(route.query.title as string);
        if (translated !== route.query.title) {
            return translated;
        }
    }

    if (props.title) {
        return $t(props.title);
    }

    return $t('shop.components.shop_error.title');
});

const shownDescription = computed<string>(() => {
    if (route.query.description) {
        const translated = $t(route.query.description as string);
        if (translated !== route.query.description) {
            return translated;
        }
    }

    if (props.description) {
        return $t(props.description);
    }

    return $t('shop.components.shop_error.description');
});

const redirectUrl = computed<string>(() => {
    const url = route.query.redirect as string;
    if (url) {
        const { resolved } = router.resolve({
            path: url,
            query: {
                sessionId: log.session,
            },
        });
        if (resolved.name !== 'error') {
            return resolved.fullPath;
        }
    }

    return '';
});

// TODO: Remove when moving to Vue 3
function $t(value: string, attr?: Record<string, string>) {
    return localization.getI18n().t(value, attr);
}
</script>

<style lang="scss" scoped>
.error-view {
    &__card {
        text-align: center;
        padding: var(--ot-spacing-2xl);

        &__icon {
            margin-bottom: var(--ot-spacing-2xl);
        }

        &__title {
        }

        &__description {
            opacity: 0.5;
        }

        &__button {
            margin-top: 2rem;
        }
    }
}
</style>
