<template>
    <div
        class="order-ticket-metadata-form-item"
        data-testid="order-ticket-metadata-form-item"
    >
        <div
            v-for="(meta, i) in metadata"
            :key="meta.guid"
        >
            <div
                v-if="
                    !(meta.is_complete && meta.metadata.name === 'seat_label')
                "
                class="order-ticket-metadata-form-item__input"
                data-testid="order-ticket-metadata-form-item-input"
            >
                <MetaInput
                    :ref="`meta_input_${i}`"
                    :uid="'meta-input-' + meta.guid + '-' + i"
                    :meta="meta"
                    :disabled="!!meta.is_complete"
                    @update:meta="updateMetadata($event, i)"
                />
            </div>
            <div
                v-else
                class="order-ticket-metadata-form-item__text"
                data-testid="order-ticket-metadata-form-item-text"
            >
                <span class="ot-text-tiny">{{
                    meta.metadata.translateName
                }}</span>
                <h4>{{ meta.value }}</h4>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { IOrderMetaData } from '@openticket/lib-order';

interface Props {
    metadata: IOrderMetaData[];
}

type Emits = {
    (event: 'update:metadata', val: IOrderMetaData[]): void;
};

const emit = defineEmits<Emits>();

const props = defineProps<Props>();

function updateMetadata(meta: IOrderMetaData, index: number): void {
    const { metadata } = props;
    metadata[index] = meta;
    emit('update:metadata', metadata);
}
</script>

<style lang="scss" scoped>
.order-ticket-metadata-form-item {
    &__input,
    &__text {
        padding: 0 1.25rem;
        margin-bottom: 1rem;
    }

    &__input:last-child {
        margin-bottom: 0;
    }
}
</style>
