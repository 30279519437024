<template>
    <dialog
        v-if="cookieManager"
        ref="cookieDialog"
        class="cookie-wall"
        @cancel.capture.prevent.stop
        @keydown.capture.esc.prevent.stop
        @close="$emit('close')"
    >
        <CookieWallSection
            v-if="state === states.landing"
            class="cookie-wall__landing"
            :title="cookieManager.messages.landing.title"
            :description="cookieManager.messages.landing.description"
        >
            <button
                class="cookie-wall__cta cookie-wall__landing__configure__cta cookie-wall__cta--variant-ghost"
                role="link"
                :title="cookieManager.messages.landing.configure.title"
                @click="goToConfigure"
            >
                {{ cookieManager.messages.landing.configure.text }}
            </button>

            <!-- eslint-disable vuejs-accessibility/no-autofocus -->
            <button
                class="cookie-wall__cta cookie-wall__landing__accept-all__cta"
                autofocus
                :title="cookieManager.messages.landing.acceptAll.title"
                @click="acceptAll"
            >
                <i class="ot-button-icon--left oti oti-check" />
                {{ cookieManager.messages.landing.acceptAll.text }}
            </button>
            <!-- eslint-enable vuejs-accessibility/no-autofocus -->

            <button
                v-if="cookieManager.mode.strict"
                class="cookie-wall__cta cookie-wall__cta--variant-outline cookie-wall__landing__reject__cta"
                :title="cookieManager.messages.landing.reject.title"
                @click="reject"
            >
                <i class="ot-button-icon--left oti oti-close" />
                {{ cookieManager.messages.landing.reject.text }}
            </button>
        </CookieWallSection>

        <CookieWallSection
            v-else-if="state === states.configure"
            class="cookie-wall__configure"
            :title="cookieManager.messages.configure.title"
        >
            <CookieWallToggles
                class="cookie-wall__configure__toggles"
                :categories="cookieManager.categories"
                :essential-label="cookieManager.messages.configure.essential"
            />

            <div class="cookie-wall__configure__select">
                <button
                    class="cookie-wall__cta cookie-wall__cta--variant-ghost cookie-wall__configure__select__select-all__cta"
                    :title="cookieManager.messages.configure.selectAll.title"
                    @click="selectAll"
                >
                    {{ cookieManager.messages.configure.selectAll.text }}
                </button>

                <span class="cookie-wall__configure__select__separator" />

                <button
                    class="cookie-wall__cta cookie-wall__cta--variant-ghost cookie-wall__configure__select__deselect-all__cta"
                    :title="cookieManager.messages.configure.deselectAll.title"
                    @click="deselectAll"
                >
                    {{ cookieManager.messages.configure.deselectAll.text }}
                </button>
            </div>

            <button
                class="cookie-wall__cta cookie-wall__configure__confirm__cta"
                :title="cookieManager.messages.configure.confirm.title"
                @click="confirm"
            >
                <i class="ot-button-icon--left oti oti-check" />
                {{ cookieManager.messages.configure.confirm.text }}
            </button>

            <button
                class="cookie-wall__cta cookie-wall__cta--variant-outline cookie-wall__configure__cancel__cta"
                :title="cookieManager.messages.configure.cancel.title"
                @click="goToLanding"
            >
                <i class="ot-button-icon--left oti oti-close" />
                {{ cookieManager.messages.configure.cancel.text }}
            </button>
        </CookieWallSection>

        <CookieWallSection
            v-else
            class="cookie-wall__error"
            :title="cookieManager.messages.error.title"
            :description="cookieManager.messages.error.description"
        >
            <button
                class="cookie-wall__cta cookie-wall__cta--variant-outline"
                :title="cookieManager.messages.error.close.title"
                @click="closeOnError"
            >
                {{ cookieManager.messages.error.close.text }}
            </button>
        </CookieWallSection>
    </dialog>
</template>

<script setup lang="ts">
import { Log, send } from '@openticket/lib-log';
import { StringMessage } from '@openticket/lib-order';
import {
    inject, onMounted, ref, watch,
} from 'vue';
import { CookieManager } from '../../composables/cookies';
import { scrollTo } from '../../utils';
import CookieWallSection from './CookieWallSection.vue';
import CookieWallToggles from './CookieWallToggles.vue';

const states: {
    readonly landing: 'landing';
    readonly configure: 'configure';
    readonly error: 'error';
} = {
    landing: 'landing' as const,
    configure: 'configure' as const,
    error: 'error' as const,
} as const;

interface Prop {
    triggerCookieWallOpen: number;
}

const props = withDefaults(defineProps<Prop>(), {
    triggerCookieWallOpen: 0,
});

const cookieManager = inject<CookieManager | null>('cookies', null);

const cookieDialog = ref<InstanceType<typeof HTMLDialogElement> | null>(null);
const state = ref<typeof states[keyof typeof states]>(states.landing);

watch(() => props.triggerCookieWallOpen, () => {
    state.value = states.landing;
    cookieDialog.value?.showModal();
    if (cookieDialog.value) {
        scrollTo({
            element: cookieDialog.value,
        });
    }
});

function acceptAll(): void {
    try {
        cookieManager?.acceptAll();

        cookieDialog.value?.close();
    } catch (e) {
        state.value = states.error;
        send(
            new StringMessage(
                'error.cookie-wall.accept-all.failed',
                'Failed to process cookie dialog: acceptAll',
                { error: e },
            ),
            Log.Error,
        );
    }
}

function closeOnError(): void {
    try {
        cookieManager?.clear();
    } catch (e) {
        state.value = states.error;
        send(
            new StringMessage(
                'error.cookie-wall.close-on-error.failed',
                'Failed to process cookie dialog: closeOnError',
                { error: e },
            ),
            Log.Error,
        );
    }

    cookieDialog.value?.close();
}

function confirm(): void {
    try {
        cookieManager?.confirm();

        cookieDialog.value?.close();
    } catch (e) {
        state.value = states.error;
        send(
            new StringMessage(
                'error.cookie-wall.confirm.failed',
                'Failed to process cookie dialog: confirm',
                { error: e },
            ),
            Log.Error,
        );
    }
}

function reject(): void {
    try {
        cookieManager?.reject();

        cookieDialog.value?.close();
    } catch (e) {
        state.value = states.error;
        send(
            new StringMessage(
                'error.cookie-wall.reject.failed',
                'Failed to process cookie dialog: reject',
                { error: e },
            ),
            Log.Error,
        );
    }
}

function goToLanding(): void {
    state.value = states.landing;
}

function goToConfigure(): void {
    state.value = states.configure;
}

function selectAll(): void {
    try {
        cookieManager?.selectAll();
    } catch (e) {
        state.value = states.error;
        send(
            new StringMessage(
                'error.cookie-wall.select-all.failed',
                'Failed to process cookie dialog: selectAll',
                { error: e },
            ),
            Log.Error,
        );
    }
}

function deselectAll(): void {
    try {
        cookieManager?.deselectAll();
    } catch (e) {
        state.value = states.error;
        send(
            new StringMessage(
                'error.cookie-wall.deselect-all.failed',
                'Failed to process cookie dialog: deselectAll',
                { error: e },
            ),
            Log.Error,
        );
    }
}

onMounted(() => {
    if (!cookieDialog.value) {
        return;
    }

    if (typeof cookieDialog.value.showModal !== 'function') {
        state.value = states.error;

        send(
            new StringMessage(
                'error.cookie-wall.mounted.dialog-api-not-supported',
                'The dialog API is not supported by this browser. Cannot manage cookies.',
            ),
            Log.Error,
        );

        return;
    }

    if (!cookieManager) {
        state.value = states.error;

        send(
            new StringMessage(
                'error.cookie-wall.mounted.not-available',
                'The cookie manager is not available. Cannot manage cookies.',
            ),
            Log.Error,
        );

        return;
    }

    void (async () => {
        if (cookieManager.state === CookieManager.pending) {
            await cookieManager.loaded;
        }

        if (cookieManager.state !== CookieManager.complete) {
            cookieDialog.value?.showModal();
        }
    })();
});
</script>

<style lang="scss" scoped>
.cookie-wall {
    border: none;
    border-radius: var(--ot-card-border-radius);
    box-shadow: var(--ot-shadow-md);
    margin: var(--ot-spacing-2xl) auto;
    padding: var(--ot-spacing-2xl);
    width: clamp(300px, var(--ot-card-max-width, 30rem), 100dvw);
    box-sizing: border-box;
    background: var(--ot-card-background);
    color: var(--ot-card-color);

    &::backdrop {
        background: rgba(0, 0, 0, 0.5);
        -webkit-backdrop-filter: blur(0.25rem); /* Webkit does not allow vars in pseudo elements */
        transform: translate3d(0, 0, 0); /* GPU accelerate blur filter on webkit */
        backdrop-filter: blur(var(--ot-spacing-2xs));
    }

    &__landing {
        &__configure__cta {
            margin-bottom: var(--ot-spacing-lg);
        }
    }

    &__configure {
        &__toggles {
            margin: var(--ot-spacing-lg) 0;
        }

        &__select {
            gap: var(--ot-spacing-xs);
            display: inline-flex;
            align-items: center;
            justify-content: flex-end;
            margin-bottom: var(--ot-spacing-lg);

            &__separator {
                display: inline-block;
                width: 1px;
                height: 1.25em;
                padding: 1px 0;
                border: none;
                border-right: 1px solid var(--ot-card-color);
                opacity: 0.25;
            }
        }
    }

    .cookie-wall &__cta {
        &:focus,
        &:focus-visible,
        &:focus-within {
            outline: 3px solid var(--ot-color-core-brand);
            outline-offset: 3px;
        }

        &--variant {
            &-ghost {
                height: auto;
                font-size: var(--ot-spacing-default);
                line-height: 1.25;
                padding: 1px var(--ot-spacing-xs);
                color: var(--ot-color-core-brand);
                background: transparent;
                box-shadow: none;
            }

            &-outline {
                background: transparent;
                color: inherit;
                border: 2px solid var(--ot-card-color);
                opacity: 0.75;
            }
        }
    }
}
</style>
