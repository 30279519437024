<!-- eslint-disable vue/multi-word-component-names -->
<!-- TODO: Make this a multi-word-component -->
<template>
    <div
        id="app"
        class="order-view"
    >
        <div v-if="initialized">
            <router-view />

            <OrderFooter @openCookieWall="triggerCookieWallOpen++" />

            <CookieWall :trigger-cookie-wall-open="triggerCookieWallOpen" />
        </div>
    </div>
</template>

<script setup lang="ts">
import Vue, { ref, inject, type Ref } from 'vue';
import { type LoadOptions, RudderAnalytics } from '@rudderstack/analytics-js';
import { type IOrderConfig, type OrderClient, StringMessage } from '@openticket/lib-order';
import { Log, send } from '@openticket/lib-log';
import type { VueLocalization } from '@openticket/vue-localization';
import type { Whitelabel } from '@openticket/lib-whitelabels';
import { useRoute, useRouter } from 'vue-router/composables';
import { setValidationLang } from '../../utils/sdk/metadata';
import VueError from '../../error';

import OrderFooter from './components/OrderFooter.vue';
import type { BaseInit, OverlayManager } from '../../types';
import CookieWall from '../../components/cookie/CookieWall.vue';
import type { CookieManager, CookiePreferences } from '../../composables/cookies';
import { getCookieMessages, initCookies } from './cookies';
import { injectOrFail } from '../../services/util/injectOrFail';

const baseInit = inject<BaseInit>('baseInit');
const overlay = inject<OverlayManager>('overlay');
const localizationInitPromise = inject<Ref<Promise<void>>>('localizationInitPromise');
const whitelabelInitPromise = inject<Ref<Promise<void>>>('whitelabelInitPromise');

const cookies = injectOrFail<CookieManager>('cookies');
const localization = injectOrFail<VueLocalization>('localization');
const order = injectOrFail<OrderClient>('order');
const whitelabel = injectOrFail<Whitelabel>('whitelabel');

const route = useRoute();
const router = useRouter();

const config = ref<IOrderConfig>();
const rudderAnalytics = ref<RudderAnalytics>(new RudderAnalytics());
const initialized = ref<boolean>(false);
const triggerCookieWallOpen = ref<number>(0);

async function created(): Promise<void> {
    localization.on('locale-change', (locale: string) => {
        setValidationLang(locale);
    });

    await Promise.all([ initCookiesOrder(), initOrder() ]);
}
// Cannot perform this in top-level due to await needed for axios.
// TODO: Remove in vue-3
void created();

async function initOrder(): Promise<void> {
    if (!overlay) {
        // TODO: Error handle overlay is undefined
        return;
    }
    const closeOverlay: () => void = overlay.show();

    await whitelabelInitPromise?.value;

    if (!whitelabel.order.shop_api_url) {
        throw new VueError('Shop API url is not set.', new Error(), 'osp.errors.missing_shop_url');
    }

    try {
        config.value = {
            baseUrl: whitelabel.order.shop_api_url,
            orderGuid: route.params.order_id,
        };

        await order.init(config.value);

        Vue.observable(order.data);

        const { shop } = order.data;

        if (!baseInit) {
            // TODO: Error handle baseInit is undefined
            return;
        }
        await baseInit(shop.guid);

        setValidationLang(localization.locale.locale);

        if (whitelabel.shop.gtm_code) {
            order.tracking.addGTM(whitelabel.shop.gtm_code);
        }

        if (
            whitelabel.shop.rudderstack_data_plane_url
        && whitelabel.shop.rudderstack_write_key
        ) {
            const rudderstackLoadOptions: Partial<LoadOptions> = {
                sendAdblockPage: true,
                // These settings should be amended when all projects are using 3.x
                // https://www.rudderstack.com/docs/sources/event-streams/sdks/rudderstack-javascript-sdk/migration-guide/#migration-considerations
                storage: {
                    encryption: {
                        version: 'legacy',
                    },
                    migrate: false,
                },
                plugins: [
                    'GoogleLinker',
                    'NativeDestinationQueue',
                    'StorageEncryptionLegacy',
                    'StorageEncryption',
                    'StorageMigrator',
                    'DeviceModeDestinations',
                ],
            };

            if (whitelabel.shop.rudderstack_cdn_url) {
                rudderstackLoadOptions.pluginsSDKBaseURL = whitelabel.shop.rudderstack_cdn_url;
            }
            if (whitelabel.shop.rudderstack_config_url) {
                rudderstackLoadOptions.configUrl = whitelabel.shop.rudderstack_config_url;
            }

            rudderAnalytics.value.load(
                whitelabel.shop.rudderstack_write_key,
                whitelabel.shop.rudderstack_data_plane_url,
                rudderstackLoadOptions,
            );

            // Make sure to remove anonymous_id from url when present
            rudderAnalytics.value.ready(() => {
                void router.replace({ query: { ajs_aid: undefined } });
            });

            // Make sure to reset all traits and start clean (only leave anonymousId intact)
            rudderAnalytics.value.reset();

            // Set new trait with current shop_id
            rudderAnalytics.value.page(
                'order',
                'order',
                {},
                {
                    context: {
                        traits: {
                            order_id: order.data.guid,
                            shop_id: order.data.shop_id,
                        },
                    },
                },
            );
        }

        const safePreferences: Partial<CookiePreferences> = cookies.getSafePreferences();
        if (
            shop.google_tag
        && (cookies.state === 'complete')
        && safePreferences
        && safePreferences.organiserMarketing
        ) {
        // At this point, we can not rely on cookies triggering a consent update to add the shop's GTM container.
        // If the cookies are accepted later, this is OK, the accept listener will try to add it again.
            order.tracking.addGTM(shop.google_tag);
        }

        try {
            if (route.params.status === 'paid') {
                order.sendLegacyEvents();
            } else {
                order.sendLegacyEventsWithoutConversion();
            }
        } catch (e) {
        // No-op - Tracking failures should not break flow!
            send(
                new StringMessage(
                    'osp.order.initOrder.send_legacy_events_failed',
                    '[initOrder] Sending events failed',
                    { error: e },
                ),
                Log.Error,
            );
        }

        // Set document title
        if (shop.name) {
            window.document.title = shop.name;
        }

        window.IsAppleDevice = _isAppleDevice();

        if (route.query.sessionId) {
            void router.replace({
                path: route.path,
                query: {
                    ...route.query,
                    sessionId: undefined,
                },
            });
        }

        send({
            href: window.location.href,
            message: 'osp.log.init.done',
            otSent: false,
            slug: 'osp.log.init.done',
            ts: Date.now(),
            getLogContext(): { [p: string]: string } {
                return {
                    base_url: whitelabel.order.shop_api_url || '',
                    entity_id: order.data.guid,
                    order_id: order.data.guid,
                    shop_id: shop.guid,
                };
            },
        }, Log.Info);
    } catch (e) {
        if (!e || typeof e !== 'object' || !('isOpenTicketError' in e) || !e.isOpenTicketError) {
            if (e instanceof Error) {
                send(
                    new VueError('initOrder:', e, 'osp.init_order.failed'),
                    Log.Info,
                );
            }
        }

        void router.push({
            name: 'error',
            query: {
                redirect: route.path,
            },
        });
    } finally {
        initialized.value = true;

        closeOverlay();
    }
}

async function initCookiesOrder(): Promise<void> {
    await localizationInitPromise?.value;

    cookies.onChange(
        (newPreferences: Partial<CookiePreferences>) => {
            cookiePreferencesUpdated(
                newPreferences,
                cookies.getComparableState(),
            );
        },
    );

    await initCookies(
        cookies,
        getCookieMessages(localization.getI18n()),
    );
}

function cookiePreferencesUpdated(preferences: Partial<CookiePreferences>, comparableState: string): void {
    try {
        send(
            new StringMessage(
                'osp.order.cookie.preferences_update.info',
                'Debug message for the order cookies',
                {
                    path: [ 'order', 'cookies', 'debug', 'message' ],
                    preferences,
                    comparableState,
                },
            ),
            Log.Info,
        );
    } catch (e) {
        console.error('Failed to log cookies debug message', e, comparableState);
    }

    try {
    // Cookie preferences can be updated when:
    // - Saved preferences are retrieved throught the cookie client;
    // - The user has manually updated (a subset of) cookie preferences;

        // The preferences can be individually present in the preferences object.
        // Personalised ads will only be accepted when both the base ads and
        // personalized ads properties are set to true.
        if (!preferences) {
            order.tracking.revokeConsent();

            return;
        }

        if (
            order.data
        && order.data.shop
        && typeof order.data.shop === 'object'
        && 'google_tag' in order.data.shop
        ) {
        // When organiserMarketing is accepted, the order is initialized and its shop has a GTM code,
        // the shop specific GTM container is loaded.
        //
        // DD-SHOP-2714
        //
        // The addGTM method can be triggered as often as convenient.
        // The method will silently ignore GTM codes which were already succesfully registered.
            const { google_tag } = order.data.shop;

            if (
                preferences.organiserMarketing
            && order.initialized
            && google_tag
            ) {
            // Ignored when the order has not finished loading
            // This is OK -> After the order is initialized, it is tried as well.
                order.tracking.addGTM(google_tag);
            }
        }

        order.tracking.updateConsent(preferences);
    } catch (e) {
        send(
            new StringMessage(
                'error.osp.order.cookie.preferences_update.failed',
                'Failed to update cookie preferences',
                {
                    path: [ 'order', 'cookies', 'failed', 'update' ],
                    preferences,
                    comparableState,
                    error: e,
                },
            ),
            Log.Error,
        );
    }
}

function _isAppleDevice(): boolean {
    try {
        return (
            [
                'iPad Simulator',
                'iPhone Simulator',
                'iPod Simulator',
                'iPad',
                'iPhone',
                'iPod',
            ].includes(navigator.platform)
            // iPad on iOS 13 detection
            || (navigator.userAgent.includes('Mac')
                && 'ontouchend' in document)
        );
    } catch {
        return false;
    }
}
</script>

<style lang="scss" scoped>
.order-view {
    text-align: center;

    &__retry-polling {
        margin-bottom: 1rem;
    }

    &__download-all {
        margin-bottom: 1rem;
    }

    &__footer {
        &__text {
            max-width: 24rem;
            margin: 1rem auto;
        }
    }
}
</style>
