<template>
    <div
        v-if="products.length"
        class="ot-card has-no-padding product-list"
    >
        <h5 class="product-list__title">
            <i class="oti oti-shop" />{{ $tc('order.components.products.title', products.length) }}
        </h5>
        <div class="product-list__content">
            <div
                v-for="(product, i) in products"
                :key="product.guid"
                class="product-list__item"
            >
                <OrderGlobalProduct
                    :product="product"
                    :opened="i === opened"
                    :locked="isLocked"
                    @toggle="toggle(i)"
                />
            </div>
        </div>
        <!--<div class="product-list__footer" v-if="shopUrl">-->
        <!--    <a :href="shopUrl" target="_blank">-->
        <!--        <button class="ot-button is-outline">-->
        <!--            <i class="ot-button-icon&#45;&#45;left oti oti-shop"></i>-->
        <!--            {{ $t('order.components.products.order_more_button') }}-->
        <!--        </button>-->
        <!--    </a>-->
        <!--</div>-->
    </div>
</template>

<script setup lang="ts">
import type { IOrderGlobalProduct, OrderClient } from '@openticket/lib-order';
import { computed, ref } from 'vue';
import OrderGlobalProduct from './OrderGlobalProduct.vue';
import { injectOrFail } from '../../../services/util/injectOrFail';

const order = injectOrFail<OrderClient>('order');
// const whitelabel = injectOrFail<Whitelabel>('whitelabel');

const opened = ref<number>(0);

const isLocked = computed<boolean>(() => (order.data.status !== 'paid'));

const products = computed<IOrderGlobalProduct[]>(() => order.data.global_products.filter(
    (product: IOrderGlobalProduct) => !product.product.class
                || ![ 'RefundProtect', 'Co2Compensate', 'CoverGenius' ].includes(
                    product.product.class,
                ),
));

// const shopUrl = computed<string | undefined>(() => (whitelabel.shop.url + order.data.shop_id));

const toggle = (index: number): void => {
    if (opened.value === index) {
        opened.value = -1;
    } else {
        opened.value = index;
    }
};
</script>

<style lang="scss" scoped>
.product-list {
    margin-bottom: 1rem;

    &__title {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 1.25rem 0;

        i {
            margin-right: 0.5rem;
        }
    }

    &__content {
        padding: 0 1.25rem;
        padding-bottom: 0.75rem;
    }

    &__item {
        margin-bottom: 0.5rem;
    }

    &__footer {
        text-align: center;
        padding: 1.25rem;
        border-top: 2px solid var(--ot-shop-color-box-accent);
    }
}
</style>
