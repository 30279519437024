<template>
    <div
        class="meta-input"
        data-testid="meta-input"
        :class="metaSelectorClass"
    >
        <ot-input-field
            :label="label"
            :description="description"
            :for="uid"
            :required="required"
            :error="isMetaPropsError()"
        >
            <DateAlt
                v-if="inputType === 'date-alt'"
                :id="uid"
                v-bind="typeProps"
                v-model="parsedValueString"
                :type="inputType"
                :disabled="disabled"
                :rules="meta.item.extra"
                :before="date.before"
                :after="date.after"
                @input="onInput"
                @blur="onBlur"
            />
            <ot-input
                v-else
                :id="uid"
                v-bind="typeProps"
                v-model="parsedValue"
                :type="inputType"
                :disabled="disabled"
                @input="onInput"
                @blur="onBlur"
            />
        </ot-input-field>
    </div>
</template>

<script setup lang="ts">
import Vue, {
    computed, onMounted, onUnmounted,
} from 'vue';
import type { VueLocalization, TranslateResult } from '@openticket/vue-localization';
import type {
    BaseMetadataItem, IOrderMetaData, OrderClient, PartialMetadata,
} from '@openticket/lib-order';
import {
    DistinctValidator,
    metadataGen,
    MetadataType,
} from '../utils/sdk/metadata';
import type {
    MetaDataItemValue,
} from '../utils/sdk/metadata';
import { getSelectorClass } from '../utils';
import { injectOrFail } from '../services/util/injectOrFail';
import { unique } from '../utils/array';
import DateAlt from './DateAlt.vue';

type I = MetaDataItemValue;

interface Props {
    meta: IOrderMetaData & { value: MetaDataItemValue };
    uid: string;
    showName?: boolean;
    disabled: boolean;
    bookerInfoId?: string | null;
}

type Emits = {
    (e: 'input', val: I): void;
    (e: 'blur', val: I): void;
    (e: 'update:meta', val: IOrderMetaData & { value: MetaDataItemValue }): void;
};

const props = withDefaults(defineProps<Props>(), {
    showName: true,
    bookerInfoId: null,
});

const emit = defineEmits<Emits>();

const localization = injectOrFail<VueLocalization>('localization');
const order = injectOrFail<OrderClient>('order');

// True if this input has been validated with errors at least once
let isInitialized = false;
let localeChangeHandler = 0;
let distinctValidator: null | DistinctValidator = null;

// Make the meta-object observable to watch errors
Vue.observable(props.meta);

onMounted(() => {
    localeChangeHandler = localization.on(
        'locale-change',
        () => {
            // This is to make sure the error values are also translated
            updateMetaTranslateName(translatedName.value);

            if (props.meta.errors.length) {
                validate(props.meta);
            }
        },
    );
});

onUnmounted(() => {
    localization.off(localeChangeHandler);
});

const metaSelectorClass = computed(() => {
    if (props.bookerInfoId) {
        return getSelectorClass('booker-info', props.bookerInfoId);
    }

    if (props.meta.item.guid) {
        return getSelectorClass('metadata', props.meta.item.guid);
    }

    return '';
});

const parsedValue = computed<MetaDataItemValue>({
    get: () => {
        if (props.meta.item.type === MetadataType.Boolean) {
            if (props.meta.value === null) {
                return null;
            }

            if (props.meta.item.extra.includes('required')) {
                if (
                    props.meta.value === 'false'
                    || props.meta.value === '0'
                    || !props.meta.value
                ) {
                    return 'false';
                }

                return 'true';
            }

            return (
                props.meta.value !== 'false'
                && props.meta.value !== '0'
                && !!props.meta.value
            );
        }

        if (props.meta.item.type === MetadataType.Values) {
            if (typeof props.meta.value === 'string') {
                updateMetaValue(props.meta.value.length
                    ? props.meta.value.split(',')
                        .map((val) => val.trim())
                    : []);
            }
        }

        return props.meta.value;
    },
    set: (val: number | string | boolean | string[] | null) => {
        if (props.meta.item.type === MetadataType.Boolean) {
            if (val === null) {
                updateMetaValue(null);
            } else if (val === 'true') {
                updateMetaValue(true);
            } else if (val === 'false' || val === '0') {
                updateMetaValue(false);
            } else {
                updateMetaValue(!!val);
            }
        } else {
            updateMetaValue(val);
        }
    },
});

const parsedValueString = computed<string | null>({
    get: () => {
        const { value } = parsedValue;
        if (typeof value === 'string') {
            return value;
        }

        return null;
    },
    set: (val: string | null) => {
        parsedValue.value = val;
    },
});

const label = computed<string | undefined>(() => {
    if (!props.showName || inputType.value === 'checkbox') {
        return undefined;
    }

    return translatedName.value;
});

const description = computed<string | undefined>(() => {
    if (!props.meta.item.shop_description) {
        return undefined;
    }

    return translateValueWithComputedSlug(
        props.meta.item.shop_description,

        // TODO Composing a slug is something we'd rather not do.
        //  Find some way to do this differently (probably map hardcoded values, or have full slug in the description...)
        (value: string) => `shop.common.metaDataDescription.${value}`,
    );
});

const translatedName = computed<string>(() => translateValueWithComputedSlug(
    props.meta.item.name,

    // TODO Composing a slug is something we'd rather not do.
    //  Find some way to do this differently (probably map hardcoded values, or have full slug in the name...)
    (value: string) => `shop.common.metaData.${value}`,
));

const date = computed<{ after: Date | null; before: Date | null }>(() => {
    if (
        props.meta.item.type !== MetadataType.Date
        || !props.meta.item.extra
    ) {
        return {
            after: null,
            before: null,
        };
    }

    const restrictions: { after: Date | null; before: Date | null } = {
        after: null,
        before: null,
    };

    for (const rule of props.meta.item.extra) {
        // direction -> 'before' or 'after'
        // equality -> does the date include the limit date
        // whenRelative -> relative date, like 'today'
        // whenAbsolute -> absolute date, exact date
        const [ , direction, equality, whenRelative, whenAbsolute ] = rule.match(
            /^(after|before)(_or_equal)?:(?:(yesterday|today|tomorrow)|([0-9]{4}-[0-9]{2}-[0-9]{2}))/,
        ) || [];

        if (!direction || !(whenRelative || whenAbsolute)) {
            continue;
        }

        // For absolute date parsing, the time is appended.
        // - Date only forms will be interpreted as UTC.
        // - Date-time forms wil be interpreted as the user's timezone.
        // https://maggiepint.com/2017/04/11/fixing-javascript-date-web-compatibility-and-reality/
        let parsedDate: Date = whenRelative
            ? new Date('NaN')
            : new Date(`${whenAbsolute}T00:00:00`);

        const relativeDayOffset: { [key: string]: number } = {
            yesterday: -1,
            today: 0,
            tomorrow: 1,
        };

        if (whenRelative && whenRelative in relativeDayOffset) {
            parsedDate = new Date();
            parsedDate.setDate(parsedDate.getDate() + relativeDayOffset[whenRelative]);
        }

        if (Number.isNaN(parsedDate.valueOf())) {
            continue;
        }

        if (direction === 'after') {
            if (!equality) {
                // The dates returned should form an inclusive range.
                // i.e. the returned dates SHOULD be valid (given that the range contains at least one date).
                parsedDate.setDate(parsedDate.getDate() + 1);
            }

            if (restrictions.after && restrictions.after > parsedDate) {
                // If a rule is present multiple times, all of them should be checked.
                // Values are only valid if they pass all rules.
                continue;
            }
        } else if (direction === 'before') {
            if (!equality) {
                // The dates returned should form an inclusive range.
                // i.e. the returned dates SHOULD be valid (given that the range contains at least one date).
                parsedDate.setDate(parsedDate.getDate() - 1);
            }

            if (restrictions.before && restrictions.before < parsedDate) {
                // If a rule is present multiple times, all of them should be checked.
                // Values are only valid if they pass all rules.
                continue;
            }
        } else {
            continue;
        }

        restrictions[direction] = parsedDate;
    }

    return restrictions;
});

const inputType = computed<string>(() => {
    switch (props.meta.item.type) {
        case MetadataType.Enum:
            return 'select';
        case MetadataType.Values:
            return 'select';
        case MetadataType.EnumOther:
            return 'select-other';
        case MetadataType.Boolean:
            if (props.meta.item.extra.includes('required')) {
                return 'selectbar';
            }
            return 'checkbox';
        case MetadataType.String:
            if (props.meta.item.extra.includes('email')) {
                return 'email';
            }
            return 'text';
        case MetadataType.Phone:
            return 'phone';
        case MetadataType.Integer:
            return 'integer';
        case MetadataType.Date:
            return 'date-alt';
        default:
            throw Error(
                `Metadata type ${props.meta.item.type} is not supported`,
            );
    }
});

const metaProps = computed<{
    name: string;
    label: string;
    errors: string[];
    [key: string]: unknown
}>(() => ({
    name: props.meta.item.name,
    label: props.meta.item.translateName,
    errors: props.meta.errors,
    ...typeProps.value,
}));

const typeProps = computed<{ [key: string]: unknown }>(() => {
    const translateOrOriginal = (
        value: string,
        slugPrefix: string,
    ): string => {
        const slug = `${slugPrefix.replace(/\.+$/, '')}.${value}`;
        const translateValue: TranslateResult = $t(slug);

        if (typeof translateValue === 'string' && translateValue !== slug) {
            return translateValue;
        }

        return value;
    };

    function getOptions(item: PartialMetadata): { [key: string]: string } {
        const inRule: undefined | string = item.extra.find((rule: string) => rule.startsWith('in:'));

        if (!inRule) {
            return {};
        }

        const uniqueOptions: Array<[ string, string ]> = inRule
            .replace(/^in:/, '')
            .split(',')
            .map((option: string) => option.trim())
            .filter(unique)
            .map((option: string) => [
                option,
                translateOrOriginal(
                    option,
                    `shop.common.metaData_options.${item.name}`,
                ),
            ]);

        return Object.fromEntries(uniqueOptions);
    }

    let options: Record<string, unknown>;
    switch (props.meta.item.type) {
        case MetadataType.Enum:
            return {
                options: getOptions(props.meta.item),
                placeholder: $t(
                    'shop.components.meta_input.enum.placeholder',
                ),
                searchLabel: $t('shop.components.meta_input.search'),
                selectionLabel: (values: string[], isOpen: boolean) => {
                    if (!props.meta.value && isOpen) {
                        return $tc(
                            'shop.components.meta_input.enum.selected',
                            values.length,
                        );
                    }

                    return '';
                },
            };
        case MetadataType.EnumOther:
            return {
                options: getOptions(props.meta.item),
                otherLabel: $t(
                    'shop.common.metaData_options.enumOther.other',
                ),
                placeholder: $t(
                    'shop.components.meta_input.enum_other.placeholder',
                ),
                searchLabel: $t('shop.components.meta_input.search'),
                selectionLabel: (
                    values: string[],
                    isOpen: boolean,
                    key: string,
                ) => {
                    if (!props.meta.value && isOpen && key !== '__other__') {
                        return $tc(
                            'shop.components.meta_input.enum_other.selected',
                            values.length,
                        );
                    }

                    return '';
                },
            };
        case MetadataType.Values:
            options = getOptions(props.meta.item);

            if (props.meta.value instanceof Array) {
                updateMetaValue(
                    props.meta.value.filter(
                        (option) => !!options[option],
                    ),
                );
            }

            return {
                options,
                multiple: true,
                placeholder: $t(
                    'shop.components.meta_input.values.placeholder',
                ),
                searchLabel: $t('shop.components.meta_input.search'),
                selectionLabel: (values: string[], isOpen: boolean) => {
                    if (values.length || isOpen) {
                        return $tc(
                            'shop.components.meta_input.values.selected',
                            values.length,
                        );
                    }

                    return '';
                },
            };
        case MetadataType.Boolean:
            return {
                label: `${props.meta.item.translateName}${
                    required.value
                        ? '<span class="ot-input-label--required">*</span>'
                        : ''
                }`,
                options: {
                    false: $t(
                        'shop.common.metaData_options.boolean.false',
                    ),
                    true: $t(
                        'shop.common.metaData_options.boolean.true',
                    ),
                },
            };
        default:
            return {};
    }
});

const required = computed<boolean>(() => (
    props.meta.item.extra.includes('required')
    || (props.meta.item.type === MetadataType.Boolean
        && props.meta.item.extra.includes('accepted'))
));

function onInput(val: I): void {
    if (props.meta.errors.length || isInitialized) {
        validate(props.meta);
    }

    emit('input', val);
}

function onBlur(val: I): void {
    validate(props.meta);

    isInitialized = true;

    emit('blur', val);
}

function translateValueWithComputedSlug(
    value: string,
    slugFn: (value: string) => string,
): string {
    let translatedValue: string = $t(value);

    if (translatedValue !== value) {
        return translatedValue;
    }

    const slug = slugFn(value);

    translatedValue = $t(slug);

    if (translatedValue !== slug) {
        return translatedValue;
    }

    return value;
}

function validate(metadata: BaseMetadataItem): void {
    if (order) {
        order.validator.metadata(metadata, true);
    } else {
        throw Error('No validator found');
    }

    if (distinctValidator) {
        // DD-4E27 - It is possible to dead-lock late personalization if
        // distinct is applied on a question with limited possible answers.
        distinctValidator.isValid(true);
    }
}

function updateMetaTranslateName(newTranslatedName: string): void {
    const { meta } = props;
    meta.item.translateName = newTranslatedName;
    emit('update:meta', meta);
}

function updateMetaValue(newValue: MetaDataItemValue): void {
    const { meta } = props;
    meta.value = newValue;
    emit('update:meta', meta);
}

function isMetaPropsError(): string | undefined {
    return metaProps.value.errors && Array.isArray(metaProps.value.errors)
        ? $t(metaProps.value.errors[0], { attribute: translatedName.value })
        : undefined;
}

// TODO: Remove when moving to Vue 3
function $t(value: string, attr?: Record<string, string>) {
    return localization.getI18n().t(value, attr);
}

// TODO: Remove when moving to Vue 3
function $tc(value: string, choice?: number, attr?: Record<string, string>) {
    return localization.getI18n().tc(value, choice, attr);
}

// This is to make sure the error values are also translated
updateMetaTranslateName(translatedName.value);

if (props.meta.item.guid) {
    distinctValidator = new DistinctValidator(() => metadataGen(order, props.meta.item.guid));
}
</script>

<style lang="scss" scoped>
.meta-input {
    &::v-deep {
        .ot-input-select .multiselect,
        .ot-input-field .ot-select > select {
            color: var(--ot-input-color);
            background-color: var(--ot-color-core-background-primary);
        }
    }
}
</style>
