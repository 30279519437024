<template>
    <div class="ot-input-date-alt">
        <div class="ot-select years">
            <!-- Todo: refactor the parent label component to properly point to this form field -->
            <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
            <select
                v-model="year"
                required
                :disabled="!!props.disabled"
            >
                <option
                    v-for="year_option in years"
                    :key="year_option"
                    :value="year_option"
                >
                    {{ year_option }}
                </option>
            </select>
            <span class="ot-select-focus" />
        </div>
        <div class="ot-select months">
            <!-- Todo: refactor the parent label component to properly point to this form field -->
            <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
            <select
                v-model="month"
                required
                :disabled="!!props.disabled"
            >
                <option
                    v-for="month_option in months"
                    :key="month_option"
                    :value="month_option"
                >
                    {{ $localization.locale.monthNamesWide[month_option - 1] }}
                </option>
            </select>
            <span class="ot-select-focus" />
        </div>
        <div class="ot-select days">
            <!-- Todo: refactor the parent label component to properly point to this form field -->
            <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
            <select
                v-model="day"
                required
                :disabled="!!props.disabled"
            >
                <option
                    v-for="day_option in days"
                    :key="day_option"
                    :value="day_option"
                >
                    {{ day_option }}
                </option>
            </select>
            <span class="ot-select-focus" />
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { type baseProps, useBaseInput } from '@openticket/vue-input';

type T = string | null;

interface Props extends baseProps<T> {
    rules: string[];
    before?: Date | null;
    after?: Date | null;
}

type Emits = {
    (e: 'input', val: T): void;
    (e: 'blur', val: T): void;
}

const props = withDefaults(defineProps<Props>(), {
    rules: () => [],
    before: null,
    after: null,
});

const emit = defineEmits<Emits>();

// @ts-expect-error -- Removed overwriting emit from BaseEmits for proper typing, as it is unused (Vue-UI fixed this)
const { newValue, onBlur, onInput } = useBaseInput<T>(props, emit);

const year = ref<number | null>(null);
const month = ref<number | null>(null);
const day = ref<number | null>(null);

const years = computed<number[]>(() => {
    const max = props.before
        ? props.before.getFullYear()
        : new Date().getFullYear() + 20;

    const min = props.after
        ? props.after.getFullYear()
        : new Date().getFullYear() - 150;

    const yearsArray: number[] = [];

    for (let i = max; i >= min; i--) {
        yearsArray.push(i);
    }

    return yearsArray;
});

const months = computed<number[]>(() => {
    let monthsArray: number[] = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12 ];

    if (props.before && props.before.getFullYear() === year.value) {
        const maxMonth = props.before.getMonth();
        monthsArray = monthsArray.slice(0, maxMonth + 1);
    }

    if (props.after && props.after.getFullYear() === year.value) {
        const minMonth = props.after.getMonth();
        monthsArray = monthsArray.slice(minMonth);
    }

    return monthsArray;
});

const days = computed<number[]>(() => {
    if (!year.value || !month.value) {
        return [];
    }

    const max = props.before
    && props.before.getFullYear() === year.value
    && props.before.getMonth() + 1 === month.value
        ? props.before.getDate()
        : daysInMonth(month.value, year.value);

    const min = props.after
    && props.after.getFullYear() === year.value
    && props.after.getMonth() + 1 === month.value
        ? props.after.getDate()
        : 1;

    const daysArray: number[] = [];

    for (let i = max; i >= min; i--) {
        daysArray.push(i);
    }

    return daysArray;
});

watch(() => newValue.value, () => {
    if (!newValue.value) {
        year.value = null;
        month.value = null;
        day.value = null;
        return;
    }

    const split = newValue.value.split('-');

    const newYear = parseInt(split[0], 10);
    if (year.value !== newYear) {
        year.value = newYear;
    }

    const newMonth = parseInt(split[1], 10);
    if (month.value !== newMonth) {
        month.value = newMonth;
    }

    const newDay = parseInt(split[2], 10);
    if (day.value !== newDay) {
        day.value = newDay;
    }
});

watch([ year, month, day ], () => {
    if (year.value && month.value && day.value) {
        const date = [
            year.value,
            zeroPad(month.value, 2),
            zeroPad(day.value, 2),
        ].join('-');
        emit('input', date);
    }
});

watch(months, () => {
    if (month.value && !months.value.includes(month.value)) {
        month.value = null;
    }
});

watch(days, () => {
    if (day.value && !days.value.includes(day.value)) {
        day.value = null;
    }
});

// source: https://stackoverflow.com/questions/1184334/get-number-days-in-a-specified-month-using-javascript
// Month here is 1-indexed (January is 1, February is 2, etc). This is
// because we're using 0 as the day so that it returns the last day
// of the last month, so you have to add 1 to the month number
// so it returns the correct amount of days
function daysInMonth(monthOfDate: number, yearOfDate: number) {
    return new Date(yearOfDate, monthOfDate, 0).getDate();
}

const zeroPad = (num: number, places: number) => String(num).padStart(places, '0');

defineExpose({
    onBlur,
    onInput,
});
</script>

<style lang="scss" scoped>
.ot-input-date-alt {
    display: flex;

    .ot-select {
        position: relative;
        display: grid;
        grid-template-areas: 'select';
        align-items: center;
        box-sizing: border-box;
        width: 100%;
        height: var(--ot-input-height);
        min-width: 2ch;
        padding: 0;
        border: var(--ot-input-border);
        border-radius: var(--ot-input-radius);
        background: var(--ot-input-background);
        box-shadow: var(--ot-input-shadow);
        color: var(--ot-input-color);
        font-family: var(--ot-text-font-family);
        font-size: 1em;
        font-weight: 500;
        line-height: 1.1;
        caret-color: var(--ot-input-focus-color);
        cursor: pointer;
        transition: var(--ot-transition-default), background-image 0ms;

        .ot-select-focus {
            display: none;
        }

        & > select {
            // A reset of styles, including removing the default dropdown arrow
            appearance: none;
            -webkit-appearance: none;
            padding: var(--ot-input-padding);
            padding-right: calc(3 * var(--ot-spacing-sm));
            margin: 0;
            width: 100%;
            background-color: transparent;
            border: none;
            outline: none;
            font-family: inherit;
            font-size: inherit;
            line-height: inherit;
            cursor: inherit;
            grid-area: select;
        }

        &::after {
            content: '';
            width: var(--ot-spacing-sm);
            height: calc(var(--ot-spacing-sm) / 2);
            margin-right: var(--ot-spacing-sm);
            background-color: var(--ot-color-core-foreground-secondary);
            clip-path: polygon(
                54% 66%,
                14% 4%,
                4% 32%,
                48% 100%,
                59% 100%,
                100% 32%,
                93% 4%,
                54% 66%
            );
            grid-area: select;
            justify-self: end;
        }

        &:not(input):not(.ot-textarea):focus-within {
            border-color: var(--ot-input-border-color);
            box-shadow: 0 0 0 1px var(--ot-input-border-color-focus);
            outline: none;
        }

        & > select:focus + .ot-select-focus,
        &:focus-within + .ot-select-focus {
            display: block;
            position: absolute;
            top: -1px;
            left: -1px;
            right: -1px;
            bottom: -1px;

            border: var(--ot-input-border-width) var(--ot-input-border-style)
                var(--ot-input-border-color-focus);
            border-radius: inherit;
            box-shadow: 0 0 0 1px var(--ot-input-border-color-focus);
            outline: none;
        }

        &[disabled] {
            border-color: var(--ot-input-color-disabled);
            color: var(--ot-input-color-disabled-invert);
            background-color: var(--ot-input-color-disabled);
            box-shadow: 0 0 0 1px var(--ot-input-color-disabled);
            cursor: not-allowed;
        }
    }

    .years {
        flex: 2;
    }

    .months {
        flex: 2;
    }

    .days {
        flex: 1;
    }

    & > * {
        margin-right: 0.5rem;

        &:last-child {
            margin-right: 0;
        }
    }
}
</style>
