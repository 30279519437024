<template>
    <CardSection
        class="refund-request-donate"
        :separator="separator"
        :accent="accent"
        :highlight-error="highlightError"
    >
        <span class="refund-request-donate__label">{{
            $t('order.components.refund_request_card.donate.label')
        }}</span>

        <ot-input-radio-group
            v-model="selectedDonateOption"
            class="refund-request-donate__options"
            :options="donateOptions"
            :class="{ disabled }"
            :disabled="disabled"
            @input="updateInputGroup"
        />
    </CardSection>
</template>

<script setup lang="ts">
import type { IOrderRefundSetting } from '@openticket/lib-order';
import { computed } from 'vue';
import type { Formatters, VueLocalization } from '@openticket/vue-localization';
import CardSection from '../CardSection.vue';
import { injectOrFail } from '../../../../services/util/injectOrFail';

interface Props {
    refundSetting: IOrderRefundSetting;
    donateOption?: number | null;

    // TODO: Temporary duplicates, current version does not like extending this compile macro. CU-86c2t49k0
    separator?: boolean;
    accent?: boolean;
    highlightError?: boolean;
    disabled?: boolean;
    // Temporary duplicates end here
}

type Emits = {
    (e: 'update:donateOption', value: number | null): void;
};

const props = withDefaults(defineProps<Props>(), {
    donateOption: null,

    // TODO: Temporary duplicates, current version does not like extending this compile macro. CU-86c2t49k0
    separator: false,
    accent: false,
    highlightError: false,
    disabled: false,
    // Temporary duplicates end here
});

const emit = defineEmits<Emits>();

const localization = injectOrFail<VueLocalization>('localization');
const i18n = localization.getI18n();
const l: Formatters = localization.formatters;

const selectedDonateOption = computed<string | null>({
    get: () => `${props.donateOption}`,
    set: (value: string | null) => {
        emit('update:donateOption', value === null ? null : +value);
    },
});

const donateOptions = computed<{ [key: number]: { label: string } }>(() => {
    if (!props.refundSetting.refund_donate_enabled) {
        return {};
    }

    const donateValues: number[] = props.refundSetting.refund_donate_options
        .split(',')
        .map((val: string) => parseInt(val.trim(), 10));

    donateValues.unshift(0);

    const options: { [key: number]: { label: string } } = {};

    for (const val of donateValues) {
        options[val] = {
            label: i18n.tc(
                'order.components.refund_request_card.donate.amount',
                val,
                { percentage: l.percentage100(val) },
            ),
        };
    }

    return options;
});

const updateInputGroup = (val: string | null) => {
    selectedDonateOption.value = val;
};
</script>

<style lang="scss" scoped>
.refund-request-donate {
    &__label {
        display: block;
        margin: 0 0 2.5rem 0;
        font-size: 0.8125rem;
        line-height: 1.25rem;
    }

    &__options::v-deep {
        & > .ot-radio {
            display: inline-block;
            width: 50%;
            text-align: initial;

            & > .ot-radio-label::before {
                background-color: var(--ot-color-core-white);
            }

            &[disabled],
            &.disabled {
                pointer-events: none;
                cursor: not-allowed;
            }

            &[disabled] > input[type='radio'],
            &.disabled > input[type='radio'],
            & > input[type='radio'][disabled],
            & > input[type='radio'].disabled {
                & + label.ot-radio-label::after {
                    background-color: var(--ot-input-color-disabled-invert);
                }
            }
        }

        &.ot-radio-group.disabled,
        &.ot-radio-group[disabled] {
            pointer-events: none;

            .ot-radio > input[type='radio'] + label.ot-radio-label::after {
                background-color: var(--ot-input-color-disabled-invert);
            }
        }
    }
}
</style>
