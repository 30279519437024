<template>
    <div class="contact-us">
        <BrandedFooter
            :show-powered-by="showPoweredBy"
            :show-locale-switch="showLocaleSwitch"
            @openCookieWall="$emit('openCookieWall')"
        />
        <br>
        <p
            v-show="showCopyright"
            class="ot-label"
        >
            {{
                $t('order.components.order_footer.copyright_text', {
                    company,
                    year,
                })
            }}
        </p>
        <br>
        <i18n
            v-if="url"
            v-show="showSupport"
            path="order.components.order_footer.contact_text_url"
            tag="p"
            class="ot-label"
        >
            <template #contactLink>
                <a :href="url">{{ strippedUrl }}</a>
            </template>
        </i18n>
        <i18n
            v-else
            v-show="showSupport"
            path="order.components.order_footer.contact_text"
            tag="p"
            class="ot-label"
        >
            <template #contactLink>
                <a :href="'mailto:' + email">{{ email }}</a>
            </template>
        </i18n>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import type { Whitelabel } from '@openticket/lib-whitelabels';
import type { CustomShopSettingsClient } from '@openticket/lib-custom-shop-settings';
import { injectOrFail } from '../../../services/util/injectOrFail';

const settings = injectOrFail<CustomShopSettingsClient>('settings');
const whitelabel = injectOrFail<Whitelabel>('whitelabel');

const company = computed<string>(() => whitelabel.name);

const email = computed<string>(() => whitelabel.order.support_email);

const url = computed<string | undefined>(() => whitelabel.order.support_url);

const strippedUrl = computed<string | undefined>(() => (
    whitelabel.order.support_url
        && whitelabel.order.support_url.replace(/([?#]).+$/, '')
));

const year = computed<string>(() => new Date().getFullYear().toString());

const showPoweredBy = computed<boolean>(() => (
    !settings
        || !settings.static
        || !settings.static.order
        || !settings.static.order.footer
        || settings.static.order.footer.showPoweredBy
        || settings.static.order.footer.showPoweredBy === null
));

const showLocaleSwitch = computed<boolean>(() => (
    !settings
        || !settings.static
        || !settings.static.order
        || !settings.static.order.footer
        || settings.static.order.footer.showLocaleSwitcher
        || settings.static.order.footer.showLocaleSwitcher === null
));

const showCopyright = computed<boolean>(() => (
    !settings
        || !settings.static
        || !settings.static.order
        || !settings.static.order.footer
        || settings.static.order.footer.showCopyright
        || settings.static.order.footer.showCopyright === null
));

const showSupport = computed<boolean>(() => (
    !settings
        || !settings.static
        || !settings.static.order
        || !settings.static.order.footer
        || settings.static.order.footer.showSupport
        || settings.static.order.footer.showSupport === null
));
</script>

<style lang="scss" scoped>
.contact-us {
    text-align: center;
    margin-top: var(--ot-spacing-lg);
    padding: 0rem 0.875rem;

    > .ot-label {
        max-width: 24rem;
        margin: 0 auto;
    }
}
</style>
