<template>
    <CardHeader
        :title="$t('order.components.refund_request_card.title')"
        :separator="separator"
        :accent="accent"
        icon="oti-logout"
    >
        <template #label>
            <h4 class="refund-request-card-header__subtitle">
                {{ event.name }}
            </h4>
            <span v-if="expired">{{
                $t('order.components.refund_request_card.info.expired', {
                    status_until: statusUntilDate ? $l.dateTime(
                        statusUntilDate,
                        Intl.DateTimeFormat().resolvedOptions().timeZone
                    ) : '?',
                })
            }}</span>
            <span v-else>{{
                $t('order.components.refund_request_card.info.description', {
                    status_until: statusUntilDate ? $l.dateTime(
                        statusUntilDate,
                        Intl.DateTimeFormat().resolvedOptions().timeZone
                    ) : '?',
                })
            }}</span>
        </template>
    </CardHeader>
</template>

<script setup lang="ts">
import type { IOrderEvent } from '@openticket/lib-order';
import { computed } from 'vue';
import CardHeader from '../CardHeader.vue';

interface Props {
    event: IOrderEvent;
    expired: boolean;

    // TODO: Temporary duplicates, current version does not like extending this compile macro. CU-86c2t49k0
    separator?: boolean;
    accent?: boolean;
    highlightError?: boolean;
    disabled?: boolean;
    // Temporary duplicates end here
}

const props = withDefaults(defineProps<Props>(), {
    expired: false,

    // TODO: Temporary duplicates, current version does not like extending this compile macro. CU-86c2t49k0
    separator: false,
    accent: false,
    highlightError: false,
    disabled: false,
    // Temporary duplicates end here
});

const statusUntilDate = computed<string | null>(() => props.event.status_until
    || (props.event.refund_setting && props.event.refund_setting.status_until));
</script>

<style lang="scss" scoped>
.refund-request-card-header {
    &__subtitle {
        margin-bottom: 0.5rem;
    }
}
</style>
