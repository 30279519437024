<template>
    <div class="order-item-heading">
        <h5
            class="order-item-heading__first"
            :class="
                `order-item-heading__first__${personalizedHeading.first ||
                    'title'}`
            "
        >
            <template v-if="personalizedHeading.first === 'title-with-seat'">
                {{ title }}
                <span class="order-item-heading__label">(<img
                    src="../../../../assets/images/icons/ticket-seated.svg"
                    aria-hidden="true"
                    alt=""
                >{{ seatLabel }})</span>
            </template>

            <template v-else-if="personalizedHeading.first === 'subtitle'">
                {{ subtitle }}
            </template>

            <template
                v-else-if="
                    personalizedHeading.first === 'personalization-notice'
                "
            >
                {{
                    $t(
                        'order.components.order_item.notice.personalization_needed'
                    )
                }}
            </template>

            <template v-else>
                {{ title }}
                <span class="order-item-heading__label">{{ countTitle }}</span>
            </template>
        </h5>

        <span
            v-if="personalizedHeading.second"
            class="order-item-heading__second"
            :class="
                `order-item-heading__second__${personalizedHeading.second ||
                    'title'}`
            "
        >
            <template v-if="personalizedHeading.second === 'title-with-seat'">
                {{ title }}
                <span class="order-item-heading__label">(<img
                    class="order-item-heading__seated-icon"
                    src="../../../../assets/images/icons/ticket-seated.svg"
                    aria-hidden="true"
                    alt=""
                >{{ seatLabel }})</span>
            </template>

            <template v-else-if="personalizedHeading.second === 'seat'">
                <img
                    class="order-item-heading__seated-icon"
                    src="../../../../assets/images/icons/ticket-seated.svg"
                    aria-hidden="true"
                    alt=""
                >{{ seatLabel }}
            </template>

            <template v-else>
                {{ title }}
                <span class="order-item-heading__label">{{ countTitle }}</span>
            </template>
        </span>

        <div
            v-if="personalizationComplete"
            class="order-item-heading__notice"
        >
            <i class="oti oti-check is-small" />
            {{ $t('order.components.order_item.notice.personalized') }}
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

interface Props {
    personalizationComplete: boolean;

    title: string;
    countTitle?: string | null;
    subtitle?: string | null;
    seatLabel?: string | null;

    isInvalid?: boolean;
    isCancelled?: boolean;
    isResold?: boolean;
    needsPersonalization?: boolean;
    simplifiedStatus?:
        | 'paid'
        | 'pending'
        | 'cancelled'
        | null;
}

const props = withDefaults(defineProps<Props>(), {
    countTitle: null,
    subtitle: null,
    seatLabel: null,
    isInvalid: false,
    isCancelled: false,
    isResold: false,
    needsPersonalization: false,
    simplifiedStatus: null,
});

const personalizedHeading = computed<{
    first:
        | 'title'
        | 'title-with-seat'
        | 'subtitle'
        | 'personalization-notice';
    second: 'title' | 'title-with-seat' | 'seat' | null;
}>(() => {
    // When the ticket has a personalized heading, it should be shown, even if the order/ticket is not valid.
    // Note, this does NOT mean that personalization is complete!
    if (props.subtitle) {
        return {
            first: 'subtitle', // TODO What if also needs personalization??
            second: props.seatLabel ? 'title-with-seat' : 'title',
        };
    }

    if (
        props.isInvalid
        || props.isCancelled
        || props.isResold
        || props.simplifiedStatus === 'cancelled'
    ) {
        // When the ticket (or order) is not valid, the regular heading should be shown
        return {
            first: 'title',
            second: props.seatLabel ? 'seat' : null,
        };
    }

    if (props.needsPersonalization) {
        // When the ticket (or order) is not valid, the regular heading should be shown
        return {
            first: 'personalization-notice',
            second: props.seatLabel ? 'title-with-seat' : 'title',
        };
    }

    return {
        first: 'title',
        second: props.seatLabel ? 'seat' : null,
    };
});
</script>

<style lang="scss" scoped>
.order-item-heading {
    flex: 1;
    overflow: hidden;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    min-width: 0;
    line-height: 1.125;
    padding: 1rem 0;

    & > &__first {
        // ot-content sets flex-grow of headings to 1
        flex-grow: 0;
        line-height: 1.25;

        &__personalization-notice {
            color: var(--ot-shop-color-brand);
        }
    }

    & > &__second {
        line-height: 1.125rem;
        font-size: 0.875rem;

        &__title-with-seat,
        &__seat {
            display: inline-flex;
            align-items: center;
        }
    }

    &__notice {
        display: flex;
        color: var(--ot-shop-color-brand);
        font-weight: 500;
        font-size: 0.813rem;
        line-height: 1rem;

        .oti {
            margin-right: var(--ot-spacing-2xs);
        }
    }

    &__label {
        display: inline-flex;
        align-items: center;
        color: var(--ot-shop-color-black);
        opacity: 0.5;
        margin-left: 0.2rem;
    }

    &__seated-icon {
        height: 1rem;
        margin-right: 0.1rem;
    }
}
</style>
