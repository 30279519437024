<template>
    <div
        class="order-item-metadata-form"
        data-testid="order-ticket-metadata-form"
    >
        <CollapseContent>
            <div>
                <form @submit.prevent="emit('save')">
                    <div
                        class="order-item-metadata-form__content"
                        data-testid="order-ticket-metadata-form-content"
                    >
                        <OrderTicketMetadataFormItem
                            :metadata="ticket.metadata"
                            @update:metadata="updateMetadataTicket"
                        />
                        <div
                            v-for="(product, i) in productsWithMetadata"
                            :key="product.guid"
                            data-testid="order-ticket-metadata-form-content-product"
                        >
                            <div
                                v-if="
                                    product.metadata && product.metadata.length
                                "
                                class="order-item-metadata-form__group"
                                data-testid="order-ticket-metadata-form-content-product-group"
                            >
                                <!-- eslint-disable-next-line vuejs-accessibility/label-has-for -->
                                <label
                                    class="
                                        ot-text-tiny-strong
                                        order-item-metadata-form__group__title
                                    "
                                    data-testid="order-ticket-metadata-form-content-product-group-label"
                                >
                                    {{ product.product.name }} ({{ i + 1 }}/{{
                                        productsWithMetadata.length
                                    }})
                                </label>
                                <OrderTicketMetadataFormItem
                                    :metadata="product.metadata"
                                    @update:metadata="updateMetadataProduct($event, product)"
                                />
                            </div>
                        </div>
                        <div
                            class="order-item-metadata-form__action"
                            data-testid="order-ticket-metadata-form-content-action"
                        >
                            <button
                                class="ot-button is-fullwidth"
                                :class="{
                                    'is-loading': loading,
                                }"
                                type="button"
                                @click="save"
                            >
                                <i
                                    class="ot-button-icon--left oti oti-check"
                                />
                                {{
                                    $t(
                                        'order.components.order_ticket_metadata_form.save_button'
                                    )
                                }}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </CollapseContent>
    </div>
</template>

<script setup lang="ts">
import type { IOrderMetaData, IOrderProduct, IOrderTicket } from '@openticket/lib-order';
import { computed } from 'vue';
import OrderTicketMetadataFormItem from './OrderTicketMetadataFormItem.vue';

interface Props {
    ticket: IOrderTicket;
    loading: boolean;
}

type Emits = {
    (event: 'update:ticket', val: IOrderTicket): void;
    (event: 'save'): void;
};
const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const productsWithMetadata = computed<IOrderProduct[]>(() => props.ticket.products.filter(
    (product: IOrderProduct) => product.metadata && product.metadata.length,
));

function save(): void {
    emit('save');
}

function updateMetadataTicket(metadata: IOrderMetaData[]): void {
    const { ticket } = props;
    ticket.metadata = metadata;
    emit('update:ticket', ticket);
}

function updateMetadataProduct(metadata: IOrderMetaData[], product: IOrderProduct): void {
    const index = props.ticket.products.findIndex(
        (p: IOrderProduct) => p.guid === product.guid,
    );
    if (index === -1) {
        return;
    }

    const { ticket } = props;
    ticket.products[index].metadata = metadata;
    emit('update:ticket', ticket);
}
</script>

<style lang="scss" scoped>
.order-item-metadata-form {
    &__content {
        padding-top: 0;
    }

    &__group {
        border-top: 2px solid Var(--ot-shop-color-box-accent);
        padding-top: 1.25rem;

        &__title {
            margin-left: 1.25rem;
            margin-bottom: 0.75rem;
            opacity: 0.5;
            display: block;
        }
    }

    &__action {
        margin: 0.5rem 1.25rem 0;
        padding-bottom: 1.25rem;
    }
}
</style>
