<template>
    <div
        class="order-ticket"
        data-testid="order-ticket"
        :class="{
            'has-status': hasStatus,
            'actionable-by-visitor': needsPersonalization,
            'in-progress': refundInProgress || isRendering,
        }"
    >
        <OrderItem
            :title="ticket.ticket.name"
            :count-title="countTitle"
            :subtitle="personalizedTitle"
            :opened="opened"
            :locked="locked"
            :invalidated-since="ticket.invalidated_since"
            :personalization-complete="personalizationComplete"
            :needs-personalization="needsPersonalization"
            :has-content="hasContent"
            :refund-in-progress="refundInProgress"
            :is-invalid="isInvalid"
            :is-cancelled="isCancelled"
            :is-resold="isResold"
            :is-sealed="isSealed"
            :seat-label="seatLabel"
            :simplified-status="simplifiedStatus"
            :wallet-enabled="walletEnabled"
            @toggle="emit('toggle')"
        >
            <template #actions>
                <a
                    v-if="isDownloadable && ticket.download_link"
                    :href="ticket.download_link"
                    data-testid="order-ticket-download-link"
                    target="_blank"
                >
                    <div
                        class="
                            order-item__heading__action
                            order-ticket__download
                        "
                        data-testid="order-item-heading-action"
                    >
                        <i class="oti oti-download" />
                        <!-- Todo: replace this label with a more sensible element -->
                        <!-- eslint-disable-next-line vuejs-accessibility/label-has-for -->
                        <label>{{
                            $t('order.components.order_ticket.buttons.download')
                        }}</label>
                    </div>
                </a>
                <div
                    v-else-if="isRendering"
                    class="order-item__heading__action order-ticket__rendering"
                    data-testid="order-item-heading-action"
                >
                    <i class="oti oti-spinner" />
                    <!-- Todo: replace this label with a more sensible element -->
                    <!-- eslint-disable-next-line vuejs-accessibility/label-has-for -->
                    <label class="order-ticket__rendering__label">{{
                        $t('order.components.order_ticket.buttons.rendering')
                    }}</label>
                </div>
                <div
                    v-else-if="walletEnabled"
                    class="order-item__heading__action"
                    data-testid="order-item-heading-action"
                >
                    <i class="oti oti-call" />
                    <!-- Todo: replace this label with a more sensible element -->
                    <!-- eslint-disable-next-line vuejs-accessibility/label-has-for -->
                    <label>{{
                        $t('order.components.order_ticket.buttons.open_in')
                    }}</label>
                </div>
            </template>
            <div>
                <div
                    v-if="showTicketActions"
                    class="order-ticket__actions"
                >
                    <OrderTicketActions
                        :show-download-button="isDownloadable"
                        :download-link="ticket.download_link"
                        :ticket="ticket"
                    />
                </div>
                <div
                    v-else
                    class="order-ticket__divider"
                />
                <div
                    v-if="ticket.ticket.description"
                    class="order-ticket__description ot-text-small"
                    data-testid="order-ticket-description"
                >
                    <MarkdownRenderer>
                        {{ ticket.ticket.description }}
                    </MarkdownRenderer>
                </div>

                <div
                    v-if="shouldShowQrCode"
                    class="order-ticket__qr"
                    data-testid="order-ticket-qr"
                >
                    <img
                        :src="qrImgUrl"
                        :alt="ticket.ticket_number"
                    >
                    <p class="ot-text-small">
                        {{ ticket.ticket_number }}
                    </p>
                </div>

                <OrderTicketProducts
                    v-if="products.length"
                    :products="products"
                />

                <OrderTicketMetadataForm
                    v-if="ticket.metadata && !ticket.is_complete"
                    ref="metadataForm"
                    :ticket="ticket"
                    :loading="loading"
                    @save="openConfirmMetaDataModal"
                    @update:ticket="updateTicket"
                />

                <div
                    v-else-if="ticket.metadata.length > 0 && ticket.is_complete"
                >
                    <OrderItemMetadata
                        :metadata="ticket.metadata"
                    />

                    <div
                        v-for="(product, i) in productsWithMetadata"
                        :key="product.guid"
                        class="order-ticket__metadata__product"
                        data-testid="order-ticket-metadata-product"
                    >
                        <!-- Todo: replace this label with a more sensible element -->
                        <!-- eslint-disable-next-line vuejs-accessibility/label-has-for -->
                        <label
                            class="
                                ot-text-tiny-strong
                                order-ticket__metadata__product__title
                            "
                        >
                            {{ product.product.name }} ({{ i + 1 }}/{{
                                productsWithMetadata.length
                            }})
                        </label>
                        <OrderItemMetadata
                            :metadata="product.metadata"
                        />
                    </div>
                </div>
            </div>
        </OrderItem>
        <OrderTicketStatus
            v-if="hasStatus"
            :ticket="ticket"
            :needs-personalization="needsPersonalization"
            :refund-in-progress="refundInProgress"
            :is-sealed="isSealed"
            :is-rendering="isRendering"
            :is-resold="isResold"
            :is-invalid="isInvalid"
            :is-cancelled="isCancelled"
            :is-locked="locked"
            :simplified-status="simplifiedStatus"
        />
        <ConfirmMetadata
            v-if="showConfirmMetadataModal"
            :ticket="ticket"
            :count-title="countTitle"
            :products="products"
            @save="saveMetadata"
            @close="showConfirmMetadataModal = false"
        />
    </div>
</template>

<script setup lang="ts">
import { StringMessage } from '@openticket/lib-order';
import type {
    IOrderMetaData,
    IOrderProduct,
    IOrderTicket,
    OrderClient,
} from '@openticket/lib-order';
import { Log, send } from '@openticket/lib-log';
import QRCode from 'qrcode';
import {
    computed,
    reactive,
    ref,
    watch,
} from 'vue';
import type VueNotifications from '@openticket/vue-notifications';
import type { VueLocalization } from '@openticket/vue-localization';
import type { CustomShopSettingsClient } from '@openticket/lib-custom-shop-settings';
import type { Whitelabel } from '@openticket/lib-whitelabels';
import OrderItem from '../OrderItem/OrderItem.vue';
import OrderItemMetadata from '../OrderItem/OrderItemMetadata.vue';
import OrderTicketMetadataForm from './OrderTicketMetadataForm.vue';
import OrderTicketProducts from './OrderTicketProducts.vue';
import OrderTicketStatus from './OrderTicketStatus.vue';
import OrderTicketActions from './OrderTicketActions.vue';
import SimplifyStatus from '../../../../utils/simplify_status';
import ConfirmMetadata from '../ConfirmMetadata.vue';
import { injectOrFail } from '../../../../services/util/injectOrFail';
import { DistinctValidator, metadataGen, metadataGenForTicket } from '../../../../utils/sdk/metadata';
import MarkdownRenderer from '../../../../components/MarkdownRenderer.vue';

interface Props {
    ticket: IOrderTicket;
    locked: boolean;
    opened: boolean;
    showQrCode?: boolean;
    hideDownloadAction?: boolean;
    index: number;
    count: number;
}

type Emit = {
    (event: 'next'): void;
    (event: 'toggle'): void;
    (event: 'update:ticket', val: IOrderTicket): void;
};

const props = withDefaults(defineProps<Props>(), {
    showQrCode: false,
    hideDownloadAction: false,
});

const emit = defineEmits<Emit>();

const order = injectOrFail<OrderClient>('order');
const notifications = injectOrFail<VueNotifications>('notifications');
const localization = injectOrFail<VueLocalization>('localization');
const settings = injectOrFail<CustomShopSettingsClient>('settings');
const whitelabel = injectOrFail<Whitelabel>('whitelabel');

const metadataForm = ref<InstanceType<typeof OrderTicketMetadataForm> | null>(null);

// TODO: https://app.clickup.com/t/86c2qk38a
const loading = ref<boolean>(false);
const showConfirmMetadataModal = ref<boolean>(false);
const distinctValidator = ref<DistinctValidator>(new DistinctValidator(() => metadataGen(order)));
const qrImgUrl = ref<string>('');

// TODO: Be very wary when moving to Vue3, the behaviour of this function changes
// See: https://v2.vuejs.org/v2/guide/migration-vue-2-7#Behavior-Differences-from-Vue-3
reactive(props.ticket);

const personalizedTitle = computed<string | null>(() => {
    try {
        const candidates: string[][] = [
            [ 'fullname' ],
            [ 'first_name', 'last_name' ],
            [ 'first_name' ],
            [ 'last_name' ],
            [ 'email' ],
        ];

        for (const candidate of candidates) {
            const values: Array<
                IOrderMetaData | undefined
            > = candidate.map((name: string) => props.ticket.metadata.find(
                (metadata: IOrderMetaData) => metadata.item.name === name,
            ));

            if (
                values.every(
                    (metadata: IOrderMetaData | undefined) => metadata
                        && typeof metadata.value === 'string'
                        && metadata.value.trim(),
                )
            ) {
                return (values as Array<{ value: string }>)
                    .map((metadata: { value: string }) => metadata.value.trim())
                    .join(' ');
            }
        }
    } catch (e) {
        return null;
    }

    return null;
});

const countTitle = computed<string>(() => {
    if (!props.index || props.count < 2) {
        return '';
    }
    return `(${props.index}/${props.count})`;
});

const products = computed<IOrderProduct[]>(() => props.ticket.products.filter((product: IOrderProduct) => product.is_optional));

const productsWithMetadata = computed<IOrderProduct[]>(() => props.ticket.products.filter(
    (product: IOrderProduct) => product.metadata && product.metadata.length,
));

const shouldShowQrCode = computed<boolean>(() => (
    props.showQrCode && !props.ticket.invalidated_since && !isSealed.value
));

const hasStatus = computed<boolean>(() => (
    (isSealed.value
            || needsPersonalization.value
            || refundInProgress.value
            || isResold.value
            || isRendering.value
            || isInvalid.value
            || isCancelled.value)
        && !(props.opened && hasContent.value)
));

const needsPersonalization = computed<boolean>(() => (
    !props.ticket.is_complete
        && props.ticket.ticket.late_personalization
        && !props.ticket.invalidated_since
));

const personalizationComplete = computed<boolean>(() => (
    props.ticket.is_complete
        && props.ticket.ticket.late_personalization
        && !props.ticket.invalidated_since
));

const refundInProgress = computed<boolean>(() => (
    !!props.ticket.invalidated_since
        && (props.ticket.invalidated_reason === 'return by visitor'
            || props.ticket.invalidated_reason === 'returned')
));

const isRendering = computed<boolean>(() => (
    !needsPersonalization.value
        && !props.ticket.computed_download_ready
        && !props.ticket.invalidated_reason
));

const isInvalid = computed<boolean>(() => !!props.ticket.invalidated_reason && !refundInProgress.value);

const isResold = computed<boolean>(() => (
    !!props.ticket.invalidated_since
        && props.ticket.invalidated_reason === 'ticket is ticketswapped'
));

const hasContent = computed<boolean>(() => (
    (props.ticket.metadata.length > 0
            || products.value.length > 0
            || productsWithMetadata.value.length > 0
            || walletEnabled.value
            || isDownloadable.value)
        && !props.locked
));

const isSealed = computed<boolean>(() => (
    !!props.ticket.retrievable_after
        && !props.ticket.download_link
        && !props.ticket.invalidated_since
        && !needsPersonalization.value
));

const isCancelled = computed<boolean>(() => (
    props.ticket.invalidated_reason === 'ticket-cancelled'
        || props.ticket.invalidated_reason === 'product-cancelled'
));

const isDownloadable = computed<boolean>(() => (
    !props.hideDownloadAction
        && !!props.ticket.download_link
        && !props.ticket.invalidated_since
        && (!settings
            || !settings.static.order.components.eventCard
                .disableDownloadButton)
        && props.ticket.computed_download_ready
));

const showTicketActions = computed<boolean>(() => (
    !isCancelled.value
        && !isSealed.value
        && !isResold.value
        && !isInvalid.value
        && !needsPersonalization.value
));

const walletEnabled = computed<boolean>(() => {
    if (order.data.status !== 'paid' || !settings) {
        return false;
    }

    const appicEnabled = settings.static.order.enableAppic
        && !!whitelabel.order.appic_url;
    const closeEnabled = !!settings.static.order.components.eventCard
        .closeUrl;
    const maveEnabled = settings.static.order.enableMave
        && !!whitelabel.order.mave_url;
    const partyPayEnabled = settings.static.order.enablePartyPay
        && !!whitelabel.order.party_pay_url;
    const ticketKeeperEnabled = settings.static.order.enableTicketKeeper
        && !!whitelabel.order.ticket_keeper_url;

    return !!(appicEnabled || closeEnabled || partyPayEnabled || maveEnabled || ticketKeeperEnabled);
});

const seatLabel = computed<string | null>(() => {
    let label = null;

    // Note, is_seated unexplicably seems to return false negatives.
    // This should be investigated, but commenting it out here for now
    // as it's only used as a minor performance optimisation.
    // if (order.data.is_seated) {
    props.ticket.metadata.forEach((metadata) => {
        if (metadata.metadata.name === 'seat_label') {
            label = metadata.value;
        }
    });
    // }

    return label;
});

const simplifiedStatus = computed<'paid' | 'pending' | 'cancelled' | null>(() => SimplifyStatus(order.data.status));

const openConfirmMetaDataModal = (): void => {
    try {
        order.validateMetadata([ ...metadataGenForTicket(props.ticket) ]);

        // DD-4E27 - It is possible to dead-lock late personalization if
        // distinct is applied on a question with limited possible answers.
        if (!distinctValidator.value.isValid()) {
            // Check if any metadata of THIS ticket is invalid, or others
            for (const metadata of metadataGenForTicket(props.ticket)) {
                if (metadata.errors.length) {
                    console.warn('metadata distinction invalid', distinctValidator.value);
                    return;
                }
            }
        }

        showConfirmMetadataModal.value = true;
    } catch (e) {
        send(
            new StringMessage(
                'osp.order_ticket.open_confirm_metadata_modal.failed_to_open',
                'Failed to open confirm metadata modal',
                { error: e },
            ),
            Log.Warn,
        );
    }
};

watch(() => props.opened, () => {
    // Focus first element element in the metadata form
    if (props.opened && !props.ticket.is_complete) {
        setTimeout(() => {
            const elem = metadataForm.value?.$el;
            const input = elem?.querySelector('input');
            if (input) {
                input.focus();
            }
        }, 300);
    }
});

async function saveMetadata(): Promise<void> {
    try {
        loading.value = true;
        const { ticket } = props;
        await order.saveTicketMetadata(ticket);
        updateTicket(ticket);
        emit('next');
        notifications.success(
            localization.getI18n().t('order.components.order_ticket.saved_personalization_message'),
        );
    } catch (e) {
        // TODO: https://app.clickup.com/t/86c2qjzq1
    } finally {
        loading.value = false;
    }
}

function updateTicket(ticket: IOrderTicket): void {
    emit('update:ticket', ticket);
}

function generateQrImageUrl(): void {
    QRCode.toDataURL(props.ticket.ticket_number)
        .then((url: string) => {
            qrImgUrl.value = url;
        })
        .catch((err: Error) => {
            console.error(err);
            qrImgUrl.value = '';
        });
}

generateQrImageUrl();
</script>

<style lang="scss" scoped>
.order-ticket {
    &__actions {
        padding: var(--ot-spacing-default);
        border-top: 2px solid var(--ot-shop-color-box-accent);
        &:not(:last-child) {
            border-bottom: 2px solid var(--ot-shop-color-box-accent);
            margin-bottom: 1rem;
        }
    }

    &__divider {
        margin: 0 var(--ot-spacing-default);
        margin-bottom: var(--ot-spacing-default);
        border-bottom: 2px solid var(--ot-shop-color-box-accent);
    }

    &__rendering {
        position: relative;
    }

    &__description {
        margin: 0 1.25rem 1.25rem;
    }

    &__qr {
        text-align: center;
        margin: 1rem 0;

        img {
            // Note: 192x192 (see qr url) with max-75%-scaling and css image-rendering: pixelated
            // seems to provide the best result.
            max-width: 75%;
            image-rendering: pixelated;
        }
    }

    &__metadata {
        &__product {
            padding-top: 1.25rem;
            border-top: 2px solid var(--ot-shop-color-box-accent);

            &__title {
                margin-left: 1.25rem;
                margin-bottom: 0.5rem;
                opacity: 0.5;
                display: block;
            }
        }
    }

    &__heading {
        &__sealed {
            display: flex;

            &__content {
                flex: 1;
                text-align: right;
                margin-right: 1rem;

                span {
                    display: block;
                    line-height: 1rem;
                    font-weight: 600;
                }
            }
        }
    }

    &.has-status {
        color: var(--ot-card-color);
        background-color: var(--ot-shop-color-box-dropdown);
        border-radius: calc(var(--ot-card-border-radius) * 1.1) calc(var(--ot-card-border-radius) * 1.1)
            var(--ot-card-border-radius) var(--ot-card-border-radius);

        &.disabled {
            color: var(--ot-card-color);
            background-color: var(--ot-card-status-disabled);
        }

        &.actionable-by-visitor {
            color: var(--ot-input-border-color-focus-invert);
            background: var(--ot-input-border-color-focus);
        }

        &.in-progress {
            color: var(--ot-shop-color-white);
            background: var(--ot-shop-color-black);
        }
    }
}
</style>
