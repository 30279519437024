<template>
    <CardSection
        class="refund-request-terms"
        :separator="separator"
        :accent="accent"
        :highlight-error="highlightError"
    >
        <ot-input-checkbox
            v-model="acceptedTerms"
            class="refund-request-terms__accepted"
            :class="{ disabled }"
            :label="$t('order.components.refund_request_card.terms')"
            :disabled="disabled"
        />
    </CardSection>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import CardSection from '../CardSection.vue';

interface Props {
    accepted: boolean;

    // TODO: Temporary duplicates, current version does not like extending this compile macro. CU-86c2t49k0
    separator?: boolean;
    accent?: boolean;
    highlightError?: boolean;
    disabled?: boolean;
    // Temporary duplicates end here
}

type Emits = {
    (e: 'update:accepted', value: boolean): void;
};

const props = withDefaults(defineProps<Props>(), {
    accepted: false,

    // TODO: Temporary duplicates, current version does not like extending this compile macro. CU-86c2t49k0
    separator: false,
    accent: false,
    highlightError: false,
    disabled: false,
    // Temporary duplicates end here
});

const emit = defineEmits<Emits>();

const acceptedTerms = computed<boolean>({
    get: () => props.accepted,
    set: (value: boolean) => emit('update:accepted', value),
});
</script>

<style lang="scss" scoped>
.refund-request-terms {
    &__accepted.ot-checkbox::v-deep {
        & > input[type='checkbox'] + label.ot-checkbox-label {
            text-align: initial;

            &::before,
            &::after {
                top: 50%;
                margin-top: -0.625rem;
            }
        }

        &[disabled],
        &.disabled {
            pointer-events: none;
        }

        &[disabled] > input[type='checkbox'],
        &.disabled > input[type='checkbox'],
        & > input[type='checkbox'][disabled],
        & > input[type='checkbox'].disabled {
            & + label.ot-checkbox-label::after {
                background-color: var(--ot-input-color-disabled-invert);
            }
        }
    }
}
</style>
