<template>
    <div
        class="order-item-metadata"
        data-testid="order-item-metadata"
    >
        <div
            v-for="meta in metadata"
            :key="meta.guid"
            class="order-item-metadata__item"
            data-testid="order-item-metadata-item"
        >
            <span class="ot-text-tiny">{{ meta.metadata.translateName }}</span>
            <h4 v-if="meta.value !== null">
                {{ $t(getValue(meta)) }}
            </h4>
            <h4
                v-else
                class="order-item-metadata__item__empty"
            >
                {{ $t('shop.common.metaData_options.empty') }}
            </h4>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { IOrderMetaData, IOrderApiMetaData } from '@openticket/lib-order';

interface Props {
    metadata: IOrderMetaData[];
}

defineProps<Props>();

function getValue(metadata: IOrderMetaData): string {
    return valueToSlug(
        metadata.item.type,
        metadata.item.name,
        metadata.value,
    );
}

function valueToSlug(type: IOrderApiMetaData['type'], name: string, value: unknown): string {
    switch (type) {
        case 'boolean':
            if (value) {
                return 'shop.common.metaData_options.boolean.true';
            }

            return 'shop.common.metaData_options.boolean.false';

        case 'values':
            if (value && Array.isArray(value)) {
                return value.join(', ');
            }
            break;
        default:
    }
    return value as string;
}
</script>

<style lang="scss" scoped>
.order-item-metadata {
    padding: 0 1.25rem 1.25rem;

    &__item {
        margin-bottom: 1rem;

        &__empty {
            opacity: 0.3;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}
</style>
