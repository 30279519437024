<template>
    <div
        class="order-ticket-actions__wallet"
        data-testid="order-ticket-actions-wallet"
    >
        <div
            class="order-ticket-actions__wallet__text"
            data-testid="order-ticket-actions-wallet-text"
        >
            <div class="ot-text-tiny">
                {{
                    $t(
                        'order.components.order_ticket_actions.apple_wallet.description'
                    )
                }}
            </div>
        </div>
        <a
            class="order-ticket-actions__wallet__badge"
            :class="{ 'overlay ot-spinner': loading, 'error-overlay': error }"
            data-testid="order-ticket-actions-wallet-badge"
            :title="
                $t(
                    'order.components.order_ticket_actions.apple_wallet.button.label'
                )
            "
            target="_blank"
            href="#"
            @click.prevent="requestPkPassFile(ticket.guid)"
        >
            <img
                src="../../../../../assets/images/icons/apple_wallet.svg"
                :alt="$t(
                    'order.components.order_ticket_actions.apple_wallet.button.label'
                )"
            >
            <div
                v-if="error"
                class="error"
            ><i class="oti oti-alert" /></div>
        </a>
    </div>
</template>

<script setup lang="ts">
import type { IOrderTicket, OrderClient } from '@openticket/lib-order';
import type { Whitelabel } from '@openticket/lib-whitelabels';
import axios from 'axios';
import urlJoin from 'url-join';
import { ref } from 'vue';
import { injectOrFail } from '../../../../../services/util/injectOrFail';

const MAX_PKPASSFILE_ATTEMPTS = 5;
const PKPASSFILE_POLL_TIMEOUT_MS = 1000;

interface Props {
    ticket: IOrderTicket;
}

defineProps<Props>();

const order = injectOrFail<OrderClient>('order');
const whitelabel = injectOrFail<Whitelabel>('whitelabel');

const loading = ref<boolean>(false);
const error = ref<boolean>(false);

const pkPassUrl = ref<string | null>(null);
const pkPassPollingAttempts = ref<number>(0);

const requestPkPassFile = async (ticketId: string): Promise<void> => {
    if (loading.value || !whitelabel.order.wallet_url) {
        return;
    }

    error.value = false;
    loading.value = true;
    const fetchUrl = urlJoin([
        whitelabel.order.wallet_url,
        '/apple',
        order.data.guid,
        ticketId,
    ]);

    try {
        await axios.get(fetchUrl);
        // Hold your horses, the catch-state is actually the state we want to have here.
        // The backend is returning a 355 status code with an X-Location header.
        // We can ignore all other 2XX status codes.
        loading.value = false;
        error.value = true;
    } catch (e) {
        if (axios.isAxiosError(e)) {
            const { response } = e;
            if (
                response
                && response.status === 355
                && response.headers['x-location'] !== ''
                && typeof response.headers['x-location'] === 'string'
            ) {
                pkPassUrl.value = response.headers['x-location'];
                setTimeout(
                    () => {
                        void _pollPkPassAvailable();
                    },
                    PKPASSFILE_POLL_TIMEOUT_MS,
                );
            } else {
                error.value = true;
                loading.value = false;
            }
        } else {
            throw e;
        }
    }
};

// Perform HEAD requests to see if the file is accessible for the user
async function _pollPkPassAvailable(): Promise<void> {
    pkPassPollingAttempts.value++;

    if (
        pkPassUrl.value === null
        || pkPassPollingAttempts.value > MAX_PKPASSFILE_ATTEMPTS
    ) {
        loading.value = false;
        error.value = true;
        pkPassPollingAttempts.value = 0;
        return Promise.resolve();
    }

    try {
        await axios.head(pkPassUrl.value);
        window.location.href = pkPassUrl.value;

        loading.value = false;
    } catch (e) {
        if (axios.isAxiosError(e)) {
            setTimeout(
                () => {
                    void _pollPkPassAvailable();
                },
                PKPASSFILE_POLL_TIMEOUT_MS,
            );
        } else {
            throw e;
        }
    }
    return Promise.resolve();
}
</script>

<style lang="scss" scoped>
.order-ticket-actions {
    &__wallet {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1em;
        margin-bottom: var(--ot-spacing-default);

        &__text {
            @media (max-width: 40em) {
                display: none;
            }
        }

        &__badge {
            position: relative;
            flex-grow: 1;
            display: flex;
            justify-content: center;

            img {
                width: 10em;
            }

            .error {
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }

            &.overlay {
                img {
                    visibility: hidden;
                }
            }

            &.error-overlay {
                img {
                    filter: grayscale(1) opacity(0.3);
                }
            }
        }
    }
}
</style>
