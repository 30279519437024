<template>
    <CardSection
        class="refund-request-payout"
        :separator="separator"
        :accent="accent"
        :highlight-error="highlightError"
    >
        <h3>{{ $t('order.components.refund_request_card.payout.title') }}</h3>

        <div class="refund-request-payout__items">
            <RefundRequestRadio
                v-for="payoutTypeValue in payoutTypes"
                :key="payoutTypeValue.type"
                class="refund-request-payout__item"
                :guid="payoutTypeValue.type"
                :selected="payoutTypeSelected === payoutTypeValue.type"
                :class="`refund-request-payout__item__${payoutTypeValue.type}`"
                :disabled="disabled"
                @input="selectType(payoutTypeValue.type, $event)"
            >
                <div class="refund-request-payout__item__label">
                    <h6>{{ $t(payoutTypeValue.slug) }}</h6>
                    <span
                        v-if="payoutAmount !== null"
                        :key="
                            `refund-request-payout__item__label.${payoutTypeValue.type}.payoutAmount.${payoutAmount}`
                        "
                    >{{
                        $l.currency(payoutAmount, $order.data.shop.currency)
                    }}</span>
                </div>
            </RefundRequestRadio>
        </div>
    </CardSection>
</template>

<script setup lang="ts">
import type { IOrderRefundSetting } from '@openticket/lib-order';
import { computed } from 'vue';
import CardSection from '../CardSection.vue';
import RefundRequestRadio from './RefundRequestRadio.vue';
import type { PayoutSpec, PayoutType } from './types';

interface Props {
    payoutType: PayoutType | null;
    refundSetting: IOrderRefundSetting;
    payoutAmount: number | null;

    // TODO: Temporary duplicates, current version does not like extending this compile macro. CU-86c2t49k0
    separator?: boolean;
    accent?: boolean;
    highlightError?: boolean;
    disabled?: boolean;
    // Temporary duplicates end here
}

type Emits = {
    (e: 'update:payoutType', value: PayoutType | null): void;
};

const props = withDefaults(defineProps<Props>(), {
    payoutType: null,

    // TODO: Temporary duplicates, current version does not like extending this compile macro. CU-86c2t49k0
    separator: false,
    accent: false,
    highlightError: false,
    disabled: false,
    // Temporary duplicates end here
});

const emit = defineEmits<Emits>();

const payoutTypeSelected = computed<PayoutType | null>({
    get: () => props.payoutType,
    set: (value: PayoutType | null) => emit('update:payoutType', value),
});

const payoutTypes = computed<PayoutSpec[]>(() => {
    if (!props.refundSetting) {
        return [];
    }

    const payoutTypesValues: PayoutSpec[] = [];

    if (props.refundSetting.refundable_by_voucher) {
        payoutTypesValues.push({
            type: 'voucher',
            slug: 'order.components.refund_request_card.payout.voucher',
        });
    }

    if (props.refundSetting.refundable_by_payment) {
        payoutTypesValues.push({
            type: 'cash',
            slug: 'order.components.refund_request_card.payout.cash',
        });
    }

    if (payoutTypesValues.length === 1) {
        updatePayoutTypeSelect(payoutTypesValues[0].type);
    } else {
        updatePayoutTypeSelect(null);
    }

    return payoutTypesValues;
});

const selectType = (type: PayoutType, val: boolean): void => {
    if (val) {
        payoutTypeSelected.value = type;
    } else if (payoutTypeSelected.value === type) {
        payoutTypeSelected.value = null;
    }
};

function updatePayoutTypeSelect(payoutType: PayoutType | null): void {
    payoutTypeSelected.value = payoutType;
}
</script>

<style lang="scss" scoped>
.refund-request-payout {
    &__items {
        margin: 2.5rem 0;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__item__label {
        display: flex;
        flex-direction: column;
    }

    &::v-deep .ot-radio {
        &[disabled],
        &.disabled {
            pointer-events: none;
        }

        &[disabled] > input[type='radio'],
        &.disabled > input[type='radio'],
        & > input[type='radio'][disabled],
        & > input[type='radio'].disabled {
            & + label.ot-radio-label::after {
                background-color: var(--ot-input-color-disabled-invert);
            }
        }
    }
}
</style>
