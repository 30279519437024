<template>
    <div
        v-if="isBeforeRefundableBefore"
        ref="refundRequestCard"
        class="ot-card refund-request-card has-no-padding"
    >
        <RefundRequestCardHeader
            :event="event"
            :expired="eventNoLongerRefundable"
            separator
        />

        <div v-if="refundSetting && tickets.length && eventRefundable">
            <RefundRequestSelect
                ref="select"
                class="refund-request-card__select"
                :refund-settings="refundSetting"
                :selected-tickets="selectedTickets"
                :tickets="tickets"
                :highlight-error="!!errors['select']"
                :disabled="busy"
                @update:selectedTickets="updateSelectedTickets"
            />

            <RefundRequestDonate
                v-if="refundSetting.refund_donate_enabled"
                ref="donate"
                class="refund-request-card__donate"
                :refund-setting="refundSetting"
                :donate-option="donateOption"
                :highlight-error="!!errors['donate']"
                :disabled="busy"
                accent
                @update:donateOption="updateDonateOption"
            />

            <RefundRequestPayout
                ref="payout"
                class="refund-request-card__payout"
                :refund-setting="refundSetting"
                :payout-type="payoutType"
                :payout-amount="payoutAmount"
                :highlight-error="!!errors['payout']"
                :disabled="busy"
                separator
                @update:payoutType="updatePayoutType"
            />

            <RefundRequestTerms
                ref="terms"
                class="refund-request-card__terms"
                :accepted="termsAccepted"
                :highlight-error="!!errors['terms']"
                :disabled="busy"
                @update:accepted="updateTermsAccepted"
            />

            <CardSection
                class="refund-request-card__submit"
                separator
            >
                <button
                    class="ot-button is-large is-fullwidth"
                    :disabled="busy"
                    @click="submit"
                >
                    {{
                        $t('order.components.refund_request_card.submit.label')
                    }}
                    <i class="oti oti-carrot-right" />
                </button>
            </CardSection>
        </div>

        <div v-else-if="refundSetting && eventRefundable">
            <CardSection>
                {{
                    $t('order.components.refund_request_card.nothing_to_refund')
                }}
            </CardSection>
        </div>

        <div v-else-if="refundSetting && !eventNoLongerRefundable">
            <CardSection>
                {{
                    $t('order.components.refund_request_card.event_not_refundable')
                }}
            </CardSection>
        </div>

        <div v-else-if="!eventNoLongerRefundable">
            <CardSection>
                {{
                    $t('order.components.refund_request_card.no_payout_profile')
                }}
            </CardSection>
        </div>
    </div>
</template>

<script setup lang="ts">
import {
    type IOrderEvent,
    type IOrderTicket,
    type IOrderRefundSetting,
    isRefundableTicket,
    isRefundableEvent,
    isNoLongerRefundableEvent,
    type OrderClient,
} from '@openticket/lib-order';
import { isOtError } from '@openticket/lib-log';
import {
    computed, del, ref, set, watch,
} from 'vue';
import type VueNotifications from '@openticket/vue-notifications';
import type { VueLocalization } from '@openticket/vue-localization';
import type VueI18n from 'vue-i18n';
import CardSection from '../CardSection.vue';
import RefundRequestCardHeader from './RefundRequestCardHeader.vue';
import RefundRequestSelect from './RefundRequestSelect.vue';
import RefundRequestDonate from './RefundRequestDonate.vue';
import RefundRequestPayout from './RefundRequestPayout.vue';
import RefundRequestTerms from './RefundRequestTerms.vue';
import type { ReturnItem } from '../../../../utils/sdk/orderbuilder';
import { scrollToOffset } from '../../../../utils';
import type { PayoutType, RefundItemCollection } from './types';
import { injectOrFail } from '../../../../services/util/injectOrFail';

interface RefundSectionRefs {
    all?: Element;
    select?: Element;
    donate?: Element;
    payout?: Element;
    terms?: Element;
}

interface Props {
    event: IOrderEvent;
}

const props = defineProps<Props>();

const notifications = injectOrFail<VueNotifications>('notifications');
const order = injectOrFail<OrderClient>('order');
const localization = injectOrFail<VueLocalization>('localization');

const donateOption = ref<number | null>(null);
const payoutAmountBeforeDonate = ref<number>(0);
const payoutAmount = ref<number>(0);
const payoutType = ref<PayoutType | null>(null);
const returnItems = ref<ReturnItem[]>([]);
const selectedTickets = ref<RefundItemCollection>({});
const termsAccepted = ref<boolean>(false);

const busy = ref<boolean>(false);
const errors = ref<{ [section: string]: number }>({});

// TODO: Replace by useTemplateRef when moving to vue 3.5
const refundRequestCard = ref<Element | null>(null);
const select = ref<InstanceType<typeof RefundRequestSelect> | null>(null);
const donate = ref<InstanceType<typeof RefundRequestDonate> | null>(null);
const payout = ref<InstanceType<typeof RefundRequestPayout> | null>(null);
const terms = ref<InstanceType<typeof RefundRequestTerms> | null>(null);

const refundSetting = computed<IOrderRefundSetting | null>(
    () => props.event.refund_setting || props.event.payout_profile,
);

const tickets = computed<IOrderTicket[]>(
    () => props.event.tickets.filter((ticket: IOrderTicket) => isRefundableTicket(props.event, ticket)),
);

const refs = computed<RefundSectionRefs>(() => ({
    all: refundRequestCard.value || undefined,
    select: select.value?.$el,
    donate: donate.value?.$el,
    payout: payout.value?.$el,
    terms: terms.value?.$el,
}));

const eventNoLongerRefundable = computed<boolean>(() => isNoLongerRefundableEvent(props.event));

const eventRefundable = computed<boolean>(() => isRefundableEvent(props.event));

const isBeforeRefundableBefore = computed<boolean>(() => {
    // When not set, fallback to default behaviour -> allow refund
    if (
        refundSetting.value === null
        || !refundSetting.value.status_refundable_before
    ) {
        return true;
    }

    return (
        new Date(order.data.created_at)
        < new Date(refundSetting.value.status_refundable_before)
    );
});

async function submit(): Promise<void> {
    if (busy.value) {
        return Promise.resolve();
    }

    if (!refundSetting.value) {
        return showAndThrowError(
            'all',
            'order.components.refund_request_card.validation.payout_profile',
        );
    }

    if (
        !Object.keys(selectedTickets.value).length
        || !returnItems.value.length
    ) {
        // TODO Decent error
        return showAndThrowError(
            'select',
            'order.components.refund_request_card.validation.select',
        );
    }

    if (
        refundSetting.value.refund_donate_enabled
        && donateOption.value === null
    ) {
        // TODO Decent error
        return showAndThrowError(
            'donate',
            'order.components.refund_request_card.validation.donate',
        );
    }

    if (!payoutType.value) {
        // TODO Decent error
        return showAndThrowError(
            'payout',
            'order.components.refund_request_card.validation.payout',
        );
    }

    if (!termsAccepted.value) {
        // TODO Decent error
        return showAndThrowError(
            'terms',
            'order.components.refund_request_card.validation.terms',
        );
    }

    // TODO: Log about to request refund + determine which data...

    try {
        busy.value = true;

        let donateOptionLocal: number | null = null;

        // The input v-model does not return numbers, only strings.
        if (
            donateOption.value !== null
            && !Number.isNaN(+donateOption.value)
        ) {
            donateOptionLocal = +donateOption.value;
        }

        await order.requestRefund(
            refundSetting.value,
            returnItems.value,
            payoutType.value === 'voucher',
            donateOptionLocal,
        );
    } catch (e: unknown) {
        busy.value = false;

        if (isOtError(e)) {
            const data = e.getLogContext() || {};
            if ('oldSlug' in data && data.oldSlug) {
                // Backwards compatibility
                const { oldSlug } = data;
                if ($te(oldSlug)) {
                    if (data && 'firstError' in data && data.firstError && typeof data.firstError === 'string') {
                        data.firstError = $t(data.firstError, data);
                    }

                    notifications.danger($t(oldSlug, data));
                    return Promise.resolve();
                }
            } else {
                // New error handling
                const { slug } = e;
                if ($te(slug)) {
                    if (data && 'firstError' in data && data.firstError && typeof data.firstError === 'string') {
                        data.firstError = $t(data.firstError, data);
                    }

                    notifications.danger($t(slug, data));
                    return Promise.resolve();
                }
            }
        }

        if (e && typeof e === 'object' && 'toString' in e && typeof e.toString === 'function') {
            // eslint-disable-next-line @typescript-eslint/no-base-to-string
            notifications.danger(e.toString());
        }

        return Promise.resolve();
    }

    // TODO: Log success + new order_id (parent: log message before the request)

    notifications.show({
        message: $t(
            'order.components.refund_request_card.submit.success',
        ),
        type: 'is-success',
    });

    window.top!.location.reload();
    return Promise.resolve();
}

function showAndThrowError(section: keyof RefundSectionRefs, message: string): Promise<void> {
    notifications.danger($t(message));

    // When the errors object has the key for the section,
    // the section is highlighted. By setting the value to the timeout,
    // it is easy to remove the highlight both automatically
    // and when a new error was received and the highlight timer should be reset.
    if (errors.value[section]) {
        window.clearTimeout(errors.value[section]);
    }

    set(
        errors.value,
        section,
        window.setTimeout(() => {
            del(errors.value, section);
        }, 750),
    );

    const refSection = refs.value[section];
    if (refSection) {
        scrollToOffset(
            Math.max(
                refSection.getBoundingClientRect().top
                + window.scrollY
                - 80,
                0,
            ),
        );
    }

    // TODO Decent error ??
    throw new Error(message);
}

function updateDonateOption(value: number | null): void {
    donateOption.value = value;
}

function updatePayoutType(value: PayoutType | null): void {
    payoutType.value = value;
}

function updateTermsAccepted(value: boolean): void {
    termsAccepted.value = value;
}

function updateSelectedTickets(value: RefundItemCollection): void {
    selectedTickets.value = value;
}

// TODO: Remove when moving to Vue 3
function $t(value: string, attr?: Record<string, string>) {
    return localization.getI18n().t(value, attr);
}

function $te(value: string, locale?: VueI18n.Locale): boolean {
    return localization.getI18n().te(value, locale);
}

watch(() => [ donateOption.value, payoutAmountBeforeDonate.value ], () => {
    payoutAmount.value = payoutAmountBeforeDonate.value * (1 - (donateOption.value || 0) / 100);
});

watch(() => selectedTickets.value, () => {
    if (!refundSetting.value) {
        return;
    }

    returnItems.value = order.getReturnItems(
        props.event,
        Object.values(selectedTickets.value),
        refundSetting.value,
    );

    let amount = 0;

    for (const returnItem of returnItems.value) {
        switch (returnItem.type) {
            case 'ticket':
                if (order.data.ticket_map[returnItem.guid]) {
                    const orderTicket: IOrderTicket = order.data.ticket_map[returnItem.guid];

                    amount += orderTicket.finn_price;

                    if (!refundSetting.value.refund_fees) {
                        amount -= orderTicket.finn_service_fee;
                    }
                }

                break;

            case 'payment':
                if (
                    order.data.payment_map[returnItem.guid]
                    && refundSetting.value.refund_transaction_fees
                ) {
                    amount += order.data.payment_map[returnItem.guid]
                        .finn_service_fee;
                }

                break;

            case 'product':
                // Ignore products, they should all be taken care of ticket.finn_price!

                break;
            default:
                break;
        }
    }

    payoutAmountBeforeDonate.value = amount;
});
</script>

<style lang="scss" scoped>
.refund-request-card {
    margin-bottom: 1rem;
    text-align: center;

    &__terms {
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
    }

    &__submit {
        font-weight: 500;

        margin: 0 0 1.25rem 0;
        padding-top: 0;
        padding-bottom: 1.25rem;

        & > .ot-button > .oti {
            margin-left: 0.75rem;
        }
    }
}
</style>
