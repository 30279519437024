<template>
    <div class="order-status">
        <BrandedHeader
            :title="title"
            :hide-logo="!showLogo"
        />

        <OrderDownloadButton
            v-if="!$settings || !$settings.static.order.disableDownloadButton"
        />

        <div class="order-status__status">
            <OrderMessages @open="scrollAndOpenIncompleteTicketCard()" />
        </div>

        <WalletsMessage v-if="showWalletIntegrations" />

        <EventList
            :opened-card="openedCard"
            @toggle="toggleCard"
            @next="openIncompleteTicketCard"
        />

        <ProductList />

        <OrderDetails />

        <PromoBlocks />

        <CoverGenius v-if="showCoverGenius" />

        <RefundProtect v-if="showRefundProtect" />

        <GreenProduct />

        <OrganiserInfo
            v-for="event in $order.data.events"
            :key="`organiser-info-${event.guid}`"
            :event="event"
        />

        <ResellCard v-if="showResellCard" />
    </div>
</template>

<script setup lang="ts">
import type { TranslateResult, VueLocalization } from '@openticket/vue-localization';
import { computed, ref } from 'vue';
import type { OrderClient } from '@openticket/lib-order';
import type { CustomShopSettingsClient } from '@openticket/lib-custom-shop-settings';
import SimplifyStatus from '../../../utils/simplify_status';

import EventList from '../components/EventList.vue';
import GreenProduct from '../components/upgrades/GreenProduct.vue';
import OrderDownloadButton from '../components/OrderDownloadButton.vue';
import OrderMessages from '../components/OrderMessages.vue';
import OrganiserInfo from '../components/OrganiserInfo.vue';
import OrderDetails from '../components/OrderDetails.vue';
import ProductList from '../components/ProductList.vue';
import RefundProtect from '../components/upgrades/RefundProtect.vue';
import ResellCard from '../components/ResellCard.vue';
import PromoBlocks from '../components/PromoBlocks.vue';
import { scrollTo, scrollToOffset } from '../../../utils';
import WalletsMessage from '../components/WalletsMessage.vue';
import CoverGenius from '../components/upgrades/CoverGenius.vue';
import { injectOrFail } from '../../../services/util/injectOrFail';

const localization = injectOrFail<VueLocalization>('localization');
const order = injectOrFail<OrderClient>('order');
const settings = injectOrFail<CustomShopSettingsClient>('settings');

const openedCard = ref<string | null>(null);

openIncompleteTicketCard();

const simplifiedStatus = computed<'paid' | 'pending' | 'cancelled' | null>(() => SimplifyStatus(order.data.status));

const title = computed<TranslateResult | null>(() => {
    switch (simplifiedStatus.value) {
        case 'paid':
            return $t('order.components.order.status.paid');

        case 'pending':
            return $t('order.components.order.status.pending');

        case 'cancelled':
            return $t('order.components.order.status.cancelled');

        default:
            return null;
    }
});

const showLogo = computed<boolean>(() => (
    !settings
        || !settings.static
        || !settings.static.order
        || !settings.static.order.header
        || settings.static.order.header.showLogo
        || settings.static.order.header.showLogo === null
));

const showWalletIntegrations = computed<boolean>(() => {
    if (order.data.status !== 'paid' || !settings) {
        return false;
    }

    return !!(
        settings.static.order.enableAppic
        || settings.static.order.enablePartyPay
        || !!settings.static.order.components.eventCard.closeUrl
        || settings.static.order.enableMave
        || settings.static.order.enableTicketKeeper
    );
});

const hasValidTicket = computed<boolean>(() => Object.values(order.data.ticket_map).some(
    (ticket) => !ticket.invalidated_reason && !ticket.invalidated_since,
));

const showCoverGenius = computed<boolean>(() => {
    if (!settings || order.data.status !== 'paid') {
        return false;
    }

    return hasValidTicket.value;
});

const showRefundProtect = computed<boolean>(() => {
    if (!settings || order.data.status !== 'paid') {
        return false;
    }

    return hasValidTicket.value;
});

const showResellCard = computed<boolean>(() => {
    if (!settings || order.data.status !== 'paid') {
        return false;
    }

    return (
        !!settings.static.order.enableResell && hasValidTicket.value
    );
});

function scrollAndOpenIncompleteTicketCard(): void {
    openIncompleteTicketCard();
    if (openedCard.value != null) {
        scrollTo({
            id: openedCard.value,
            position: 'center',
        });
    }
}

function openIncompleteTicketCard(): void {
    // Open first incomplete ticket, and closes if we can not find one
    for (const ticket of Object.values(order.data.ticket_map)) {
        if (!ticket.is_complete && !ticket.invalidated_since) {
            openedCard.value = ticket.guid;
            return;
        }
    }
    openedCard.value = null;
    scrollToOffset(0);
}

function toggleCard(guid: string): void {
    if (openedCard.value !== guid) {
        openedCard.value = guid;
    } else {
        openedCard.value = null;
    }
}

// TODO: Remove when moving to Vue 3
function $t(value: string, attr?: Record<string, string>) {
    return localization.getI18n().t(value, attr);
}
</script>
