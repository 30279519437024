import Vue from 'vue';
import MetaInput from './MetaInput.vue';
import SplashScreen from './SplashScreen.vue';
import BrandedFooter from './BrandedFooter.vue';
import BrandedHeader from './BrandedHeader.vue';
import SiteLogo from './SiteLogo.vue';
import LoadingSpinner from './LoadingSpinner.vue';
import CollapseContent from './CollapseContent.vue';

// GENERAL COMPONENTS
Vue.component('BrandedHeader', BrandedHeader);
Vue.component('SplashScreen', SplashScreen);
Vue.component('CollapseContent', CollapseContent);
Vue.component('BrandedFooter', BrandedFooter);
Vue.component('MetaInput', MetaInput);
Vue.component('SiteLogo', SiteLogo);
Vue.component('LoadingSpinner', LoadingSpinner);
