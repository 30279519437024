<!-- eslint-disable vue/multi-word-component-names -->
<!-- TODO: Make this a multi-word-component -->
<template>
    <div id="app">
        <div class="app">
            <div class="app__container">
                <router-view />
            </div>
            <SplashScreen
                :visible="overlayManager.visible"
                :label="overlayManager.visible ? overlayManager.label : null"
            />
        </div>
    </div>
</template>

<script async setup lang="ts">
import axios from 'axios';
import type { PreferredLocales, VueLocalization } from '@openticket/vue-localization';
import type { StyleTheme } from '@openticket/lib-style';
import type Style from '@openticket/lib-style';
import { Log, send } from '@openticket/lib-log';
import { StringMessage } from '@openticket/lib-order';
import Vue, { provide, ref } from 'vue';
import type { Whitelabel } from '@openticket/lib-whitelabels';
import { useRoute } from 'vue-router/composables';
import type { CustomShopSettingsClient } from '@openticket/lib-custom-shop-settings';
import { initCustomTranslations } from './services/localization';
import type { OverlayManager } from './types';
import { injectOrFail } from './services/util/injectOrFail';

const overlayManager: OverlayManager = {
    label: null,
    visible: false,
    show: (label?: string) => {
        overlayManager.label = label || null;
        overlayManager.visible = true;

        return () => {
            overlayManager.visible = false;
            overlayManager.label = null;
        };
    },
};
provide(
    'overlay',
    overlayManager,
);

const baseInitialized = ref<boolean>(false);
provide('baseInitialized', baseInitialized);

const localizationInitPromise = ref<Promise<void>>();
provide('localizationInitPromise', localizationInitPromise);

const whitelabelInitPromise = ref<Promise<void>>();
provide('whitelabelInitPromise', whitelabelInitPromise);

provide(
    'baseInit',
    async (shop_id: string): Promise<void> => {
        await localizationInitPromise.value;

        await Promise.all([
            initShopSettings(shop_id),
            whitelabelInitPromise.value,
        ]);

        await initCustomTranslations(localization, shop_id, whitelabel.order.custom_translations_url);
    },
);

const localization = injectOrFail<VueLocalization>('localization');
const settings = injectOrFail<CustomShopSettingsClient>('settings');
const style = injectOrFail<Style>('style');
const whitelabel = injectOrFail<Whitelabel>('whitelabel');
const route = useRoute();

async function created() {
    localizationInitPromise.value = initLocalization();
    whitelabelInitPromise.value = initWhitelabel();

    await Promise.all([
        localizationInitPromise.value,
        whitelabelInitPromise.value,
    ]);

    baseInitialized.value = true;
}
// Cannot perform this in top-level due to await needed for axios.
// TODO: Remove in vue-3
void created();

async function initWhitelabel(): Promise<void> {
    await whitelabel.init();
    Vue.observable(whitelabel);
}

async function initShopSettings(shopId: string): Promise<void> {
    if (!settings || !whitelabel.order.custom_shop_settings_url) {
        return;
    }

    const disableCache = route.query.nocache !== undefined;

    // On Safari < 16 (and other niche browsers), just skip the abort signal
    let abortSignal;
    if (
        'AbortSignal' in window
        && !!window.AbortSignal
        && 'timeout' in AbortSignal
    ) {
        abortSignal = AbortSignal.timeout(5000);
    }

    await settings.init({
        baseUrl: whitelabel.order.custom_shop_settings_url,
        disableCache,
        shopId,
        httpRead: axios.create({
            timeout: 5000,
            signal: abortSignal,
        }),
    });

    // Set custom style props
    if (settings.static && settings.static.style) {
        style.setStyle(settings.static.style);
    }

    // Support dark mode via ?theme=dark query param
    const query = new URLSearchParams(window.location.search);
    const queryTheme = query.get('theme');

    // Only set shop settings theme if no queryTheme is provided
    if (queryTheme) {
        style.setTheme(queryTheme as StyleTheme);
    } else if (settings.static && settings.static.theme) {
        style.setTheme(
            settings.static
        && (settings.static.theme as StyleTheme),
        );
    }
}

async function initLocalization(): Promise<void> {
    localization.on('locale-change', (locale: string) => {
        void (async () => {
            // Note that initial setting of the locale is done separately,
            // as the whitelabel services might not be initialized yet at this point!
            try {
                await whitelabelInitPromise.value;
                whitelabel.setLocale(locale);
            } catch (e) {
                // No-op -> Should not stop initialization flow!
                send(
                    new StringMessage(
                        'osp.base.initLocalization.failed_to_set_locale',
                        'Failed to set locale on whitelabel service',
                        { error: e },
                    ),
                    Log.Error,
                );
            }
        })();
    });

    const preferredLocales: PreferredLocales = localization.preferredLocales();

    const localeGetParamOverwrite = route.query.locale as
    | string
    | null;

    await localization.init({
        locale: {
            defaults: [
                localeGetParamOverwrite,
                preferredLocales.userOverwrite,
                preferredLocales.browser,
            ],
        },
        urls: {
            translations_combination_key: 'shop',
        },
    });
}
</script>

<style lang="scss">
@import './assets/scss';

.app {
    padding: 2.5rem 0.5rem;
    box-sizing: border-box;
    transition: var(--ot-transition-default);

    @include mobile {
        padding: 1.5rem 0.5rem;
        padding-bottom: 1rem;
        padding-top: 2rem;
    }

    &__container {
        margin: 0 auto;
        max-width: var(--ot-shop-layout-width);
    }
}
</style>
