<template>
    <div class="order-pos">
        <BrandedHeader
            :title="title"
            hide-logo
        />

        <OrderMessages />

        <BackToShop />

        <EventCard
            v-for="event in $order.data.events"
            :key="event.guid"
            :class="`ot-elem-event-${event.guid}`"
            :event="event"
            :opened-card="openedCard"
            show-qr-codes
            hide-download-actions
            @toggle="toggleCard"
            @next="closeCard"
            @update:event="updateEvent"
        />

        <PrintOrder />

        <OrderDetails show-qr-codes />
    </div>
</template>

<script setup lang="ts">
import type { TranslateResult, VueLocalization } from '@openticket/vue-localization';
import { computed, ref } from 'vue';
import type { OrderClient, IOrderEvent } from '@openticket/lib-order';
import SimplifyStatus from '../../../utils/simplify_status';

import BackToShop from '../components/BackToShop.vue';
import EventCard from '../components/EventCard.vue';
import OrderDetails from '../components/OrderDetails.vue';
import PrintOrder from '../components/PrintOrder.vue';
import OrderMessages from '../components/OrderMessages.vue';
import { injectOrFail } from '../../../services/util/injectOrFail';

const localization = injectOrFail<VueLocalization>('localization');
const order = injectOrFail<OrderClient>('order');

const openedCard = ref<string | null>(null);

const simplifiedStatus = computed<'paid' | 'pending' | 'cancelled' | null>(() => SimplifyStatus(order.data.status));

const title = computed<TranslateResult | null>(() => {
    switch (simplifiedStatus.value) {
        case 'paid':
            return $t('order.components.order.status.paid');

        case 'pending':
            return $t('order.components.order.status.pending');

        case 'cancelled':
            return $t('order.components.order.status.cancelled');

        default:
            return null;
    }
});

function toggleCard(guid: string): void {
    if (openedCard.value !== guid) {
        openedCard.value = guid;
    } else {
        openedCard.value = null;
    }
}

function closeCard(): void {
    openedCard.value = null;
}

function updateEvent(event: IOrderEvent): void {
    const index = order.data.events.findIndex(
        (e: IOrderEvent) => e.guid === event.guid,
    );
    if (index === -1) {
        return;
    }

    order.data.event_map[event.guid] = event;
    order.data.events[index] = event;
}

// TODO: Remove when moving to Vue 3
function $t(value: string, attr?: Record<string, string>) {
    return localization.getI18n().t(value, attr);
}
</script>
