<template>
    <div>
        <div
            v-for="event in $order.data.events"
            :key="event.guid"
            :class="`ot-elem-event-${event.guid}`"
        >
            <EventCard
                :event="event"
                :opened-card="openedCard"
                @toggle="toggle"
                @next="emit('next')"
                @update:event="updateEvent"
            />

            <RefundRequestCard
                v-if="
                    event.status_until ||
                        (event.refund_setting &&
                            event.refund_setting.status_until)
                "
                :event="event"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import type { IOrderEvent, OrderClient } from '@openticket/lib-order';
import EventCard from './EventCard.vue';
import RefundRequestCard from './RefundRequestCard/RefundRequestCard.vue';
import { injectOrFail } from '../../../services/util/injectOrFail';

interface Props {
    openedCard?: string | null;
}

type Emits = {
    (event: 'toggle', guid: string): void;
    (event: 'next'): void;
};

withDefaults(defineProps<Props>(), {
    openedCard: null,
});

const emit = defineEmits<Emits>();

const order = injectOrFail<OrderClient>('order');

function toggle(guid: string): void {
    emit('toggle', guid);
}

function updateEvent(event: IOrderEvent): void {
    const index = order.data.events.findIndex(
        (e: IOrderEvent) => e.guid === event.guid,
    );
    if (index === -1) {
        return;
    }

    order.data.event_map[event.guid] = event;
    order.data.events[index] = event;
}
</script>
