<template>
    <CardSection
        class="refund-request-select"
        :separator="separator"
        :accent="accent"
        :highlight-error="highlightError"
    >
        <h3 class="refund-request-select__title">
            {{ $t('order.components.refund_request_card.select.title') }}
        </h3>

        <ot-input-checkbox
            v-model="selectEntireEvent"
            class="refund-request-select__entire-event"
            :label="
                $t('order.components.refund_request_card.select.entire_event')
            "
            :disabled="disabled"
            :class="{ disabled }"
            @input="entireEventChanged"
        />

        <div
            class="refund-request-select__items refund-request-select__tickets"
        >
            <RefundRequestCheckbox
                v-for="ticket in tickets"
                :key="ticket.guid"
                class="
                    refund-request-select__item refund-request-select__ticket
                "
                :guid="ticket.guid"
                :selected="!!ticketSelection[ticket.guid]"
                :disabled="disabled"
                :class="[
                    `refund-request-select__order-ticket__guid__${ticket.guid}`,
                    `refund-request-select__order-ticket__ticket-number__${ticket.ticket_number}`,
                    `refund-request-select__ticket__guid__${ticket.ticket.guid}`,
                ]"
                @input="selectTicket(ticket, $event)"
            >
                <div class="refund-request-select__item__label">
                    <h6>{{ ticket.ticket.name }}</h6>
                    <div class="refund-request-select__item__label__sublabel">
                        <span>{{ ticket.ticket_number }}</span>
                        <span
                            :key="
                                `refund-request-select__item__label__sublabel`+
                                    `.ticket.${ticket.guid}.finn_price.${ticket.finn_price}`
                            "
                        >{{
                            $l.currency(
                                !refundSettings ||
                                    refundSettings.refund_fees
                                    ? ticket.finn_price
                                    : ticket.finn_price -
                                        ticket.finn_service_fee,
                                $order.data.shop.currency
                            )
                        }}</span>
                    </div>
                </div>
            </RefundRequestCheckbox>
        </div>

        <!-- <div
            class="refund-request-select__items refund-request-select__products"
        > -->
        <!-- Currently data does not properly capture shop-products / shoptionals, so can't add these (yet) -->
        <!-- </div> -->
    </CardSection>
</template>

<script setup lang="ts">
import type { IOrderRefundSetting, IOrderTicket } from '@openticket/lib-order';
import { computed, ref, watch } from 'vue';
import CardSection from '../CardSection.vue';
import RefundRequestCheckbox from './RefundRequestCheckbox.vue';
import type { RefundItemCollection } from './types';

interface Props {
    refundSettings: IOrderRefundSetting;
    tickets: IOrderTicket[];
    selectedTickets: RefundItemCollection;

    // TODO: Temporary duplicates, current version does not like extending this compile macro. CU-86c2t49k0
    separator?: boolean;
    accent?: boolean;
    highlightError?: boolean;
    disabled?: boolean;
    // Temporary duplicates end here
}

type Emits = {
    (e: 'update:selectedTickets', value: RefundItemCollection): void;
};

const props = withDefaults(defineProps<Props>(), {
    // TODO: Temporary duplicates, current version does not like extending this compile macro. CU-86c2t49k0
    separator: false,
    accent: false,
    highlightError: false,
    disabled: false,
    // Temporary duplicates end here
});

const emit = defineEmits<Emits>();

const selectEntireEvent = ref<boolean>(false);

const ticketSelection = computed<RefundItemCollection>({
    get: () => props.selectedTickets,
    set: (value: RefundItemCollection) => emit('update:selectedTickets', value),
});

const allTicketsSelected = computed<boolean>(() => {
    for (const ticket of props.tickets) {
        if (!ticketSelection.value[ticket.guid]) {
            return false;
        }
    }

    return true;
});

watch(() => allTicketsSelected.value, () => {
    selectEntireEvent.value = allTicketsSelected.value;
});

const selectTicket = (ticket: IOrderTicket, value = true): void => {
    const newList = { ...ticketSelection.value };

    if (value) {
        newList[ticket.guid] = ticket;
    } else {
        delete newList[ticket.guid];
        selectEntireEvent.value = false;
    }

    ticketSelection.value = newList;
};

const entireEventChanged = (val: boolean): void => {
    const newList = { ...ticketSelection.value };

    for (const ticket of props.tickets) {
        if (val) {
            newList[ticket.guid] = ticket;
        } else {
            delete newList[ticket.guid];
        }
    }

    ticketSelection.value = newList;
    selectEntireEvent.value = val;
};
</script>

<style lang="scss" scoped>
.refund-request-select {
    &__entire-event {
        display: inline-flex;
        width: unset;

        margin: 1rem 0 0 0;
    }

    &__items {
        margin: 2.5rem 0;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__item__label {
        display: flex;
        flex-direction: column;

        &__sublabel {
            display: flex;
            justify-content: space-between;
        }
    }

    &::v-deep .ot-checkbox {
        & > input[type='checkbox'] + label.ot-checkbox-label {
            padding: 1rem 1.25rem 1rem 3.25rem;
            text-align: initial;

            &::before,
            &::after {
                top: 50%;
                left: 1.25rem;
                margin-top: -0.625rem;
            }
        }

        &[disabled],
        &.disabled {
            pointer-events: none;
        }

        &[disabled] > input[type='checkbox'],
        &.disabled > input[type='checkbox'],
        & > input[type='checkbox'][disabled],
        & > input[type='checkbox'].disabled {
            & + label.ot-checkbox-label::after {
                background-color: var(--ot-input-color-disabled-invert);
            }
        }
    }
}
</style>
