<template>
    <dialog
        ref="confirmMetadataDialog"
        class="confirm-metadata-dialog"
        @close="emit('close')"
    >
        <div class="confirm-metadata-dialog__contents">
            <div class="ot-card">
                <h2>{{ $t('order.components.confirm_metadata.title') }}</h2>
                <div class="confirm-metadata-dialog__contents__header">
                    <span
                        class="ot-text-tiny"
                        data-testid="confirm-metadata-dialog__contents__header__span"
                    >{{
                        $t('order.components.confirm_metadata.ticket')
                    }}</span>
                    <h4>
                        {{ ticket.ticket.name }}
                        <span class="confirm-metadata-dialog__contents__count">{{
                            countTitle
                        }}</span>
                    </h4>
                </div>
                <OrderTicketProducts
                    v-if="products.length"
                    :products="products"
                />
                <OrderItemMetadata :metadata="ticket.metadata" />
                <div
                    v-for="(product, i) in productsWithMetadata"
                    :key="product.guid"
                    class="confirm-metadata-dialog__contents__product"
                >
                    <span
                        class="ot-input-label confirm-metadata-dialog__contents__product__header"
                    >
                        {{ product.product.name }} ({{ i + 1 }}/{{ productsWithMetadata.length }})
                    </span>
                    <OrderItemMetadata :metadata="product.metadata" />
                </div>
                <div class="confirm-metadata-dialog__contents__footer">
                    <button
                        class="ot-button is-light confirm-metadata-dialog__contents__back"
                        @click="emit('close')"
                    >
                        {{ $t('order.components.confirm_metadata.back') }}
                    </button>
                    <button
                        class="ot-button confirm-metadata-dialog__contents__save"
                        @click="save"
                    >
                        <i class="oti oti-check ot-button-icon--left" />
                        {{ saveText }}
                    </button>
                </div>
            </div>
        </div>
    </dialog>
</template>

<script setup lang="ts">
import type { IOrderProduct, IOrderTicket, OrderClient } from '@openticket/lib-order';
import type { TranslateResult, VueLocalization } from '@openticket/vue-localization';
import { computed, onMounted, ref } from 'vue';
import OrderItemMetadata from './OrderItem/OrderItemMetadata.vue';
import OrderTicketProducts from './OrderTicket/OrderTicketProducts.vue';
import { injectOrFail } from '../../../services/util/injectOrFail';

interface Props {
    ticket: IOrderTicket;
    countTitle: string;
    products: IOrderProduct[];
}

type Emit = {
    (event: 'save'): void;
    (event: 'close'): void;
};

const props = defineProps<Props>();
const emit = defineEmits<Emit>();

const order = injectOrFail<OrderClient>('order');
const localization = injectOrFail<VueLocalization>('localization');

const confirmMetadataDialog = ref<InstanceType<typeof HTMLDialogElement> | null>(null);

const saveText = computed<TranslateResult>(() => {
    if (isLastIncomplete.value) {
        return $t('order.components.confirm_metadata.finish');
    }
    return $t('order.components.confirm_metadata.next');
});

const isLastIncomplete = computed<boolean>(() => {
    let c = 0;
    for (const ticket of Object.values(order.data.ticket_map)) {
        if (!ticket.is_complete && !ticket.invalidated_since) {
            c++;
        }
    }
    return c === 1;
});

const productsWithMetadata = computed<IOrderProduct[]>(() => props.ticket.products.filter(
    (product: IOrderProduct) => product.metadata && product.metadata.length,
));

function save(): void {
    emit('save');
    emit('close');
}

// TODO: Remove when moving to Vue 3
function $t(value: string, attr?: Record<string, string>) {
    return localization.getI18n().t(value, attr);
}

onMounted(() => {
    confirmMetadataDialog.value?.showModal();
});
</script>

<style lang="scss" scoped>
.confirm-metadata-dialog {
    background: transparent;
    border: none !important;

    &::backdrop {
        background: rgba(0, 0, 0, 0.5);
        -webkit-backdrop-filter: blur(0.25rem); /* Webkit does not allow vars in pseudo elements */
        transform: translate3d(0, 0, 0); /* GPU accelerate blur filter on webkit */
        backdrop-filter: blur(var(--ot-spacing-2xs));
    }

    &__contents {
        display: flex;
        align-items: center;
        min-height: 100vh;

        .ot-card {
            width: calc(100% - 1rem);
            max-width: calc(var(--ot-shop-layout-width) - 1rem);
            margin: 0 auto;
            padding-top: 1.25rem;
        }

        h2 {
            font-size: 1.5rem;
            text-align: center;
            padding: 1.25rem;
            width: auto;
        }

        &__footer {
            display: flex;
            padding: 1.25rem;
            justify-content: space-between;
        }

        &__back.is-light.ot-button {
            color: var(--ot-shop-color-brand);
            background-color: var(--ot-shop-color-white);
            font-size: 0.875rem;
        }

        &__save {
            font-size: 1rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        &__header {
            padding: 1.25rem;
        }

        &__count {
            color: var(--ot-shop-color-black);
            opacity: 50%;
        }

        .order-item-metadata {
            padding-top: 1.25rem;
        }
    }

    &__product {
        border-top: 2px solid var(--ot-shop-color-box-accent);
        padding-top: 1rem;

        &__header {
            padding: 0 1.25rem;
        }
    }
}
</style>
